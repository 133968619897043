import axios from "axios";
import {URL_INVOICE_API} from "./Constantes";

export default class InvoiceApi {

  static login(user: any, pwd: any, callback: any, loginFailed: any) {
    axios({
      method: "POST",
      url: URL_INVOICE_API + "/login",
      responseType: "stream",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
      },
      data: {
        username: user,
        password: pwd
      }
    }).then(function (response) {
      if (response.status != 200) {
        //dosn't really work
        console.log("error");
      } else {
        callback(response.data, response.headers);
      }
    }).catch(error => {
      loginFailed()
    });
  }

  static getInvoiceById(id: any, callback: any) {
    axios({
      method: "GET",
      url: URL_INVOICE_API + "/invoices/"+id,
      responseType: "stream",
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    }).catch((error: Error) => {
      if (error.message.includes('401')) {
        window.localStorage.removeItem("user");
        window.location.reload()
      }
    });
  }

  static getAllInvoices(callback: any, callbackTokenExpired: any) {
    axios({
      method: "GET",
      url: URL_INVOICE_API + "/invoices",
      responseType: "stream",
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
        //token expired
        if (response.status == 401) {
          callbackTokenExpired()
        }
      } else {
        callback(response.data);
      }
    }).catch((error: Error) => {
      if (error.message.includes('401')) {
        window.localStorage.removeItem("user");
        window.location.reload()
      }
    });
  }

  static getUserById(id: any, callback: any) {
    axios({
      method: "GET",
      url: URL_INVOICE_API + "/users/" + id,
      responseType: "stream",
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    });
  }

  static getUserByUsername(username: any, callback: any, callbackFailed: any) {
    axios({
      method: "GET",
      url: URL_INVOICE_API + "/public/users/getByUsername/" + username,
      responseType: "stream",
    }).then(function (response) {
        callback(response.data);
    }).catch(function (error) {
      callbackFailed()
    });
  }

  static getAllProviders(callback: any) {
    axios({
      method: "GET",
      url: URL_INVOICE_API + "/providers",
      responseType: "stream",
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    }).catch((error: Error) => {
      if (error.message.includes('401')) {
        window.localStorage.removeItem("user");
        window.location.reload()
      }
    });
  }

  static getInvoicesDsi(callback: any, callbackTokenExpired: any) {
    axios({
      method: "GET",
      url: URL_INVOICE_API + "/invoices/getInvoiceDsi",
      responseType: "stream",
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
        if (response.status == 401) {
          callbackTokenExpired()
        }
      } else {
        callback(response.data);
      }
    }).catch((error: Error) => {
      if (error.message.includes('401')) {
        window.localStorage.removeItem("user");
        window.location.reload()
      }
    });
  }

  static getInvoicesMg(callback: any, callbackTokenExpired: any) {
    axios({
      method: "GET",
      url: URL_INVOICE_API + "/invoices/getInvoiceMg",
      responseType: "stream",
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
        if (response.status == 401) {
          callbackTokenExpired()
        }
      } else {
        callback(response.data);
      }
    }).catch((error: Error) => {
      if (error.message.includes('401')) {
        window.localStorage.removeItem("user");
        window.location.reload()
      }
    });
  }

  static getInvoiceByUserId(providerId: any, callback: any, callbackTokenExpired: any) {
    axios({
      method: "GET",
      params: {
        userId: providerId
      },
      url: URL_INVOICE_API + "/invoices/getByUserid",
      responseType: "stream",
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
        if (response.status == 401) {
          callbackTokenExpired()
        }
      } else {
        callback(response.data);
      }
    }).catch((error: Error) => {
      if (error.message.includes('401')) {
        window.localStorage.removeItem("user");
        window.location.reload()
      }
    });
  }

  static createInvoice(invoice: any, callback: any) {
    axios({
      method: "POST",
      url: URL_INVOICE_API + "/invoices",
      responseType: "stream",
      data: invoice,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "Authorization": `Bearer ${this.getToken()}`
      }
    }).then(function (response) {
      if (response.status != 201) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    });
  }

  static createUser(user: any, callback: any) {
    axios({
      method: "POST",
      url: URL_INVOICE_API + "/users",
      responseType: "stream",
      data: user,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "Authorization": `Bearer ${this.getToken()}`
      }
    }).then(function (response) {
      if (response.status != 201) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    });
  }

  static createUserPublic(user: any, callback: any) {
    axios({
      method: "POST",
      url: URL_INVOICE_API + "/public/users/create",
      responseType: "stream",
      data: user,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      }
    }).then(function (response) {
      if (response.status != 201) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    });
  }

  static createProvider(provider: any, callback: any) {
    axios({
      method: "POST",
      url: URL_INVOICE_API + "/providers",
      responseType: "stream",
      data: provider,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "Authorization": `Bearer ${this.getToken()}`
      }
    }).then(function (response) {
      if (response.status != 201) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    });
  }

  static modifyInvoice(invoice: any, callback: any) {
    axios({
      method: "PUT",
      url: URL_INVOICE_API + "/invoices/" + invoice.id,
      responseType: "stream",
      data: invoice,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "Authorization": `Bearer ${this.getToken()}`
      }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    });
  }

  static modifyUser(user: any, callback: any) {
    axios({
      method: "PUT",
      url: URL_INVOICE_API + "/users/" + user.id,
      responseType: "stream",
      data: user,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "Authorization": `Bearer ${this.getToken()}`
      }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    });
  }

  static modifyPassword(user: any, callback: any) {
    axios({
      method: "POST",
      url: URL_INVOICE_API + "/usersupdatepass/" + user.id,
      responseType: "stream",
      data: user,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "Authorization": `Bearer ${this.getToken()}`
      }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    });
  }
 
  static resetPassword(user: any, callback: any) {
    axios({
      method: "PUT",
      url: URL_INVOICE_API + "/login",
      responseType: "stream",
      data: user,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
      },
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    });
  }

  static uploadFile(selectedFile: any, fileTypeBk: any, callback: any) {
    const formData = new FormData();
    formData.append(
      "file",
      selectedFile
    );
    // formData.append("invoiceid", invoiceId)
    formData.append("fileTypeBk", fileTypeBk)
    axios({
      method: "POST",
      url: URL_INVOICE_API + "/file/upload",
      responseType: 'stream',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${this.getToken()}`
      },
      data: formData

    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data)
      }
    });
  }

  static deleteFile(fileId: any) {
    axios({
      method: "DELETE",
      url: URL_INVOICE_API + "/file/" + fileId,
      responseType: 'stream',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${this.getToken()}`
      }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      }
    });
  }

  static getUsersByTypes(type: any, callback: any) {
    axios({
      method: "GET",
      url: URL_INVOICE_API + "/users/getByType?type=" + type,
      responseType: "stream",
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data);
      }
    }).catch((error: Error) => {
      if (error.message.includes('401')) {
        window.localStorage.removeItem("user");
        window.location.reload()
      }
    });
  }

  static getHistoryUsers(year: any, callback: any) {
    axios({
      method: "GET",
      url: URL_INVOICE_API + "/historyUsers?year=" + year,
      responseType: "stream",
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        callback(response.data, year);
      }
    });
  }

  static downloadFile(url: any, filename: any) {
    axios({
      method: "GET",
      url: url,
      responseType: 'blob', // Important
      headers: { Authorization: `Bearer ${this.getToken()}` }
    }).then(function (response) {
      if (response.status != 200) {
        console.log("error");
        console.log(response);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  static getToken() {
    const userStorage = localStorage.getItem("user");
    if (userStorage) {
      return JSON.parse(userStorage)["token"];
    }
    return undefined
  }
}
