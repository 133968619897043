import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import EnhancedTableHeadTable from "./TableHead";
import TableBody from "@mui/material/TableBody";
import {DialogContent, TablePagination, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useState} from "react";
import {Data, getComparator, headCells, Order, stableSort} from "./Constantes";
import InvoiceApi from "./InvoiceApi";
import Providers from "./Providers";
import {Select} from "antd";
import RowTableRequests from "./RowTableRequests";
import {PlusOutlined} from "@ant-design/icons";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const { Option } = Select;

export default function Requests() {

    const [page, setPage] = React.useState(0);
    const [addMode, setAddMode] = React.useState(false);
    const [initialState, setInitialState] = React.useState(true);
    const [updatePageProviders, setUpdatePageProviders] = React.useState(false);
    const [form, setForm] = React.useState({companyName: undefined, companyDetails: undefined, phone: undefined, rib: undefined, address: undefined});
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState(undefined);
    let [filteredRowsExtern, setFilteredRowsExtern] = React.useState([]);
    let [optionsSelect, setOptionSelect] = useState([])
    const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
    const [showErrorDialog, setShowErrorDialog] = React.useState(false);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        InvoiceApi.getUsersByTypes( 'extern', usersReadyExtern)
        InvoiceApi.getAllProviders(cbProviders)
    },[])

    function cbProviders(res: any) {
        let list: any = []
        const resSorted = res.sort(compare)
        resSorted.forEach((provider: any) => {
            list.push(<Option value={provider.id}>{provider.companyName}</Option>)
        })
        setOptionSelect(list)
    }

    function compare( a: any, b: any ) {
        if ( a.companyName < b.companyName ){
            return -1;
        }
        if ( a.companyName > b.companyName ){
            return 1;
        }
        return 0;
    }

    function toggleDialog() {
        setShowConfirmationDialog(!showConfirmationDialog)
    }

    function toggleDialogError() {
        setShowErrorDialog(!showErrorDialog)
    }

    function usersReadyExtern(res: any) {
        setFilteredRowsExtern(res)
    }

    function updateEvent() {
        InvoiceApi.getUsersByTypes( 'extern', usersReadyExtern)
    }

    function showForm() {
        setAddMode(!addMode)
    }

    function save() {
        setInitialState(false)
        if (form.companyName && form.companyDetails && form.phone && form.address) {
            InvoiceApi.createProvider(form, saveCallBack)
        }
    }

    function saveCallBack() {
        toggleDialog()
        InvoiceApi.getAllProviders(cbProviders)
        setUpdatePageProviders(!updatePageProviders)
    }
    
    function changeRaison(e: any) {
        setForm({...form, companyName: e.target.value})
    }
    
    function changeDetails(e: any) {
        setForm({...form, companyDetails: e.target.value})
    }
    
    function changeRib(e: any) {
        const text = e.target.value
        const lastCharacterTyped = text.substring(text.length-1, text.length)
        if (lastCharacterTyped>=0 && lastCharacterTyped<=9) {
            setForm({...form, rib: e.target.value})
        } else {
            setForm({...form, rib: text.substring(0, text.length-1)})
        }
    }

/*    function changeMail(e) {
        setForm({...form, mail: e.target.value})
    }*/

    function changePhone(e: any) {
        const text = e.target.value
        const lastCharacterTyped = text.substring(text.length-1, text.length)
        if (lastCharacterTyped>=0 && lastCharacterTyped<=9) {
            setForm({...form, phone: e.target.value})
        }
        else {
            setForm({...form, phone: text.substring(0, text.length-1)})
        }
    }

    function changeAddress(e: any) {
        setForm({...form, address: e.target.value})
    }

    return (<div>
        <h2>Gestion des utilisateurs externes</h2>
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={'table-management'}>
                <EnhancedTableHeadTable
                    // numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    headCells={headCells}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={filteredRowsExtern.length}
                />
                <TableBody>
                    {stableSort(filteredRowsExtern, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        // <RowMainTable key={row.id} row={row} />
                        <RowTableRequests user={row} toggleDialogErreur={toggleDialogError} updateComponent={updateEvent} optionsSelect={optionsSelect} ></RowTableRequests>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          lang={'fr'}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            labelRowsPerPage={'Nombre de lignes par page'}
            count={filteredRowsExtern.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <div className={'user-top-div'}>
            <div className={'user-title'}><h2>Liste des fournisseurs</h2></div>
            <div className={'user-plus'}><PlusOutlined onClick={showForm}></PlusOutlined></div>
        </div>
        {addMode && <Box component="form"
          sx={{
              '& .MuiTextField-root': { m: 1, width: '30ch' },
          }}
          autoComplete="off">
            <TextField error={!initialState && !form.companyName} label={'Raison sociale'} onChange={changeRaison}></TextField>
            <TextField error={!initialState && !form.companyDetails} label={'Détails'} onChange={changeDetails}></TextField>
            {/*<TextField error={!initialState && !form.mail} label={'Mail'} onChange={changeMail}></TextField>*/}
            <TextField error={!initialState && !form.phone} label={'Téléphone'}
                       value={form.phone}
                       onChange={changePhone}></TextField>
            <TextField
              id="outlined-multiline-static"
              label="Adresse"
              multiline
              style={{width: "50ch"}}
              rows={4}
              error={!initialState && !form.address}
              onChange={changeAddress}
               />
            <div>
                <Button variant={'contained'} style={{marginBottom: '10px', float: 'right'}} size={'large'} onClick={save}>Ajouter</Button>
            </div>
        </Box>}

        <Providers updatePage={updatePageProviders}/>

        <Dialog
          open={showConfirmationDialog}
          onClose={toggleDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirmation"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Fournisseur crée
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleDialog}>OK</Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={showErrorDialog}
            onClose={toggleDialogError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Erreur"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Vous devez d'abord choisir un fournissuer
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleDialogError}>OK</Button>
            </DialogActions>
        </Dialog>
    </div>)
}
