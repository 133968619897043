import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'raf/polyfill';
import 'core-js/es/map';
import 'core-js/es/set';
import "babel-polyfill";
import 'core-js/stable';
import 'regenerator-runtime/runtime'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, Routes} from "react-router-dom";
import CollapsibleTable from "./MainTable";
import InvoiceDetail from "./InvoiceDetail";
import Login from "./Login";
import SignUp from "./SignUp";
import {Provider} from "react-redux";
import {store} from "./reducers";
import Statistics from "./Statistics";
import MyAccount from "./MyAccount";
import UserManagement from "./UserManagement";
import Requests from "./Requests";
import DashRetard from "./DashRetard";
import DashClosed from "./DashClosed";
import DashCours from "./DashCours";
import ReinitPassword from './ReinitPassword';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Login></Login>}>
            <Route path="dashboard" element={<CollapsibleTable></CollapsibleTable>}></Route>
            <Route path="dashboardRetard" element={<DashRetard></DashRetard>}></Route>
            <Route path="dashboardClosed" element={<DashClosed></DashClosed>}></Route>
            <Route path="dashboardCours" element={<DashCours></DashCours>}></Route>
            <Route path="statistics" element={<Statistics></Statistics>}></Route>
            <Route path="invoice-crud/:invoiceId" element = {<InvoiceDetail></InvoiceDetail>}>
            </Route>
            <Route path="myaccount" element={<MyAccount></MyAccount>}>
            </Route>
            <Route path="usermanagement" element={<UserManagement></UserManagement>}>
            </Route>
            <Route path="requests" element={<Requests></Requests>}>
            </Route>
          </Route>
          <Route path={'/signup/:userId'} element={<SignUp></SignUp>}></Route>
          <Route path={'/signup'} element={<SignUp></SignUp>}></Route>
          <Route path={'/reinit-password'} element={<ReinitPassword></ReinitPassword>}></Route>

          
        </Routes>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals( );
