import * as React from "react";
import {useEffect, useState} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import {useSelector} from "react-redux";
import {Col, Row} from "antd";
import {calculerDelaiTraitement, PAID, VERIF} from "./Constantes";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import {TextField} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import enLocale from "date-fns/locale/en-US";
import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import deLocale from "date-fns/locale/de";
import moment from "moment";
import BlocStats from "./BlocStats";
import InvoiceApi from "./InvoiceApi";
import f1 from "./1.png";
import f2 from "./2.png";
import f3 from "./3.png";
import f4 from "./4.png";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
  de: deLocale,
};

export default function Statistics() {

  const invoices = useSelector((state) => state.invoice.invoices)
  const [toValue, setToValue] = React.useState(null);
  const [tauxEchus, setTauxEchus] = React.useState(0);
  const [tauxEchusMois, setTauxEchusMois] = React.useState(0);
  const [tauxEchusNMoinUn, setTauxEchusNMoinUn] = React.useState(0);
  const [tauxReussite, setTauxReussite] = React.useState(0);
  const [tauxReussiteMois, setTauxReussiteMois] = React.useState(0);
  const [tauxReussiteNMoinUn, setTauxReussiteNMoinUn] = React.useState(0);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const currentMonth = new Date().getMonth() + 1;
  const previousYear = currentYear-1;
  const firstDayPreviousYear = moment(previousYear+"-01-01", "YYYY-MM-DD")
  const lastDayPreviousYear = moment(previousYear+"-12-31", "YYYY-MM-DD")
  const constantes = useSelector((state) => state.invoice.constantes)

  useEffect(() => {
    let firstDay = moment(currentYear+"-01-01", "YYYY-MM-DD")
    let firstDayMonth = moment(currentYear + "-" + currentMonth + "-01", "YYYY-MM-DD")
    setToValue(moment())
    document.getElementById('dso-current').innerHTML = computeDSO(applyFromFilter(firstDay, invoices))
    document.getElementById('dso-month').innerHTML = computeDSO(applyFromFilter(firstDayMonth, invoices))
    document.getElementById('dso-previous').innerHTML = computeDSO(applyAllFilter(firstDayPreviousYear, lastDayPreviousYear))
    computeTauxEchu(firstDay, moment(), firstDayPreviousYear, lastDayPreviousYear, firstDayMonth)
    createCharts(applyFromFilter(firstDay, invoices));
    createChartXY(firstDay, moment(), firstDayPreviousYear, lastDayPreviousYear, firstDayMonth);

    const element = document.querySelectorAll('[aria-labelledby="id-125-title"]')
    const element2 = document.querySelectorAll('[aria-labelledby="id-61-title"]')
    const element3 = document.querySelectorAll('[aria-labelledby="id-189-title"]')
    const element8 = document.querySelectorAll('[aria-labelledby="id-8122-title"]')
    const element4 = document.querySelectorAll('[aria-labelledby="id-9022-title"]')
    const element5 = document.querySelectorAll('[aria-labelledby="id-9086-title"]')
    const element6 = document.querySelectorAll('[aria-labelledby="id-9150-title"]')
    const element7 = document.querySelectorAll('[aria-labelledby="id-9300-title"]')
    const element9 = document.querySelectorAll('[aria-labelledby="id-367-title"]')
    if (element?.length) {
      element[0].style.display = "none"
    }
    if (element2?.length) {
      element2[0].style.display = "none"
    }
    if (element3?.length) {
      element3[0].style.display = "none"
    }
    if (element4?.length) {
      element4[0].style.display = "none"
    }
    if (element5?.length) {
      element5[0].style.display = "none"
    }
    if (element6?.length) {
      element6[0].style.display = "none"
    }
    if (element7?.length) {
      element7[0].style.display = "none"
    }
    if (element8?.length) {
      element8[0].style.display = "none"
    }
    if (element9?.length) {
      element9[0].style.display = "none"
    }
    InvoiceApi.getHistoryUsers(currentYear, historyCb)

  }, []);

  function historyCb(res, year) {

    const statY = res[0]
    const statYM1 = res[1]
    const arrayResult = []
    statY.forEach(element => {
      const occuranceInNM1 = statYM1.filter(x => x.user == element.user)
      arrayResult.push({user: element.user, countY: element.count, countYM1: occuranceInNM1.length? occuranceInNM1[0].count: 0})
    })
    // console.log(arrayResult)
    var chart = am4core.create("chartUser", am4charts.XYChart);
    chart.data = arrayResult

// Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "user";
    // categoryAxis.title.text = "Local country offices";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Nombre d'actions effectuées";

// Create series
    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "countYM1";
    series2.dataFields.categoryX = "user";
    series2.name = year-1 + "";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series2.columns.template.width = am4core.percent(100);

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "countY";
    series.dataFields.categoryX = "user";
    series.name = year + "";
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.width = am4core.percent(100);

// Add cursor
    chart.cursor = new am4charts.XYCursor();

// Add legend
    chart.legend = new am4charts.Legend();

  }

  function computeNbFacturesPayeePeriode(invoices, dateDebut, dateFin) {
    let compteur = 0
    invoices.forEach(invoice=> {
      const statusPaiement = invoice.historyList.filter(history => {
        return history.nouveauStatut == PAID
      })
      const datePaiement = statusPaiement?.length? statusPaiement[0].dateAction: undefined
      if (datePaiement && moment(datePaiement, "YYYY-MM-DD").toDate() > dateDebut && moment(datePaiement, "YYYY-MM-DD").toDate() < dateFin) {
        compteur++
      }
    })
    return compteur
  }

  function computeNbFacturesCreePeriode(invoices, dateDebut, dateFin) {
    let compteur = 0
    invoices.forEach(invoice=> {
      if (moment(invoice.creationdate, "YYYY-MM-DD").toDate() > dateDebut && moment(invoice.creationdate, "YYYY-MM-DD").toDate() < dateFin) {
        compteur++
      }
    })
    return compteur
  }

  function computeTauxEchu (firstDay, lastDay, firstDayPreviousY, lastDayPreviousY, firstDayChosenMonth) {
    // let firstDayCurrentYear = moment(currentYear+"-01-01", "YYYY-MM-DD")
    const invoicesThisYear = applyAllFilter(firstDay, lastDay)
    const invoicesThisMonth = applyAllFilter(firstDayChosenMonth, lastDay)
    const invoicesLastYear = applyAllFilter(firstDayPreviousY, lastDayPreviousY)
    const paidInvoicesThisYear = filterPaidInvoices(invoicesThisYear)
    const paidInvoicesThisMonth = filterPaidInvoices(invoicesThisMonth)
    const paidInvoicesLastYear = filterPaidInvoices(invoicesLastYear)

    const invoicesTransformed = addDelaiToInvoices(paidInvoicesThisYear).reduce(reducerByField('delaiType'), [])
    const invoicesTransformedMonth = addDelaiToInvoices(paidInvoicesThisMonth).reduce(reducerByField('delaiType'), [])

    if (!(invoicesTransformed.length > 1)) setTauxEchus(0);
    else setTauxEchus(invoicesTransformed[1].value / invoicesThisYear.length * 100);

    if (!(invoicesTransformedMonth.length > 1)) setTauxEchusMois(0);
    else setTauxEchusMois(invoicesTransformedMonth[1].value / invoicesThisMonth.length * 100);

    if (!invoicesTransformed.length) setTauxReussite(0);
    else setTauxReussite(invoicesTransformed[0].value / invoicesThisYear.length * 100)

    if (!invoicesTransformedMonth.length) setTauxReussiteMois(0);
    else setTauxReussiteMois(invoicesTransformedMonth[0].value / invoicesThisMonth.length * 100)

    const invoicesTransformedLastY = addDelaiToInvoices(paidInvoicesLastYear).reduce(reducerByField('delaiType'), [])
    if (!(invoicesTransformedLastY.length > 1)) setTauxEchusNMoinUn(0);
    else setTauxEchusNMoinUn(invoicesTransformedLastY[1].value / invoicesLastYear.length * 100);
    if (!invoicesTransformedLastY.length) setTauxReussiteNMoinUn(0);
    else setTauxReussiteNMoinUn(invoicesTransformedLastY[0].value / invoicesLastYear.length * 100)
  }

  function filterPaidInvoices (invoices) {
    const paidInvoices = invoices.filter(invoice => {
      return invoice.status == PAID
    })
    return paidInvoices
  }

  function computeDSO (invoices) {
    const paidInvoices = invoices.filter(invoice => {
      return invoice.status == PAID
    })

    let sommeDesDelaisTraietement = 0
    let nombreDesFacturesPayeesAvecDate = 0
    paidInvoices.forEach(invoice => {
      const delai = calculerDelaiTraitement(invoice)
      nombreDesFacturesPayeesAvecDate++;
      if (delai) {
        sommeDesDelaisTraietement += delai*1;
      }
    })

    if (nombreDesFacturesPayeesAvecDate > 0) {
      return (sommeDesDelaisTraietement / nombreDesFacturesPayeesAvecDate).toFixed(0)
    }
    return 0;
  }

  function createChartXY(firstDay, lastDay, firstDayPreviousY, lastDayPreviousY, firstDayChosenMonth) {
// Create chart instance
    var chart = am4core.create("chartCree", am4charts.XYChart);
    const currentYearCreated = computeNbFacturesCreePeriode(invoices, firstDay, lastDay)
    document.getElementById("total-current").innerHTML = currentYearCreated

    const previousYearCreated = computeNbFacturesCreePeriode(invoices, firstDayPreviousY, lastDayPreviousY)
    document.getElementById("total-previous").innerHTML = previousYearCreated

    const monthCreated = computeNbFacturesCreePeriode(invoices, firstDayChosenMonth, lastDay)
    document.getElementById("total-month").innerHTML = monthCreated

    const currentYearPaid = computeNbFacturesPayeePeriode(invoices, firstDay, lastDay)
    const previousYearPaid = computeNbFacturesPayeePeriode(invoices, firstDayPreviousY, lastDayPreviousY)

/*    const thisY = new Date().getFullYear();
    const firstDayThisY = moment(thisY+"-01-01", "YYYY-MM-DD")
    const firstDayPreviousYear = moment(thisY-1+"-01-01", "YYYY-MM-DD")
    const lastDayPreviousYear = moment(thisY-1+"-12-31", "YYYY-MM-DD")*/
    const year = firstDay.year();
    const facturesThisY = applyAllFilter(firstDay, lastDay)
    const facturesLastY = applyAllFilter(firstDayPreviousY, lastDayPreviousY)
    const paidInvoicesThisYear = filterPaidInvoices(facturesThisY)
    const paidInvoicesLastYear = filterPaidInvoices(facturesLastY)
    const countDelaiThisY = countDelai(addDelaiToInvoices(paidInvoicesThisYear))[0]
    const countHorsDelaiThisY = countDelai(addDelaiToInvoices(paidInvoicesThisYear))[1]
    const countDelaiLastY = countDelai(addDelaiToInvoices(paidInvoicesLastYear))[0]
    const countHorsDelaiLastY = countDelai(addDelaiToInvoices(paidInvoicesLastYear))[1]

    const data = [{category: "Traitées dans les délais", value: countDelaiThisY, value2: countDelaiLastY},
      {category: "Traitées hors délais", value: countHorsDelaiThisY, value2: countHorsDelaiLastY},
      {category: "Factures en cours", value: facturesThisY.length - paidInvoicesThisYear.length, value2: facturesLastY.length - paidInvoicesLastYear.length}]
    // const data = [{category: previousYear + "", value: previousYearCreated, value2: previousYearPaid}, {category: currentYear + "", value: currentYearCreated, value2: currentYearPaid}]
    chart.data = data


// Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    // categoryAxis.title.text = "Local country offices";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;

    var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Nombre de factures";

    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "value2";
    series2.dataFields.categoryX = "category";
    series2.name = year-1 + "";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series2.columns.template.width = am4core.percent(100);

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "category";
    series.name = year + "";
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.width = am4core.percent(100);

// Add cursor
    chart.cursor = new am4charts.XYCursor();

// Add legend
    chart.legend = new am4charts.Legend();
  }

  function countDelai(invoicesWithDelai) {
    let countDelai = 0
    let countHorsDelai = 0
    invoicesWithDelai.forEach(invoice=> {
      if (invoice.delaiType == 'Dans les délais') countDelai ++
      else countHorsDelai ++
    })
    return [countDelai, countHorsDelai]
  }

  function createCharts (listInvoices) {

    const invoicesTransformedType = listInvoices.reduce(reducerByField('type'), [])
    invoicesTransformedType.forEach (transFormedInvoice => {
      if (! transFormedInvoice.category) transFormedInvoice.category = "Aucun"
    })
    const invoicesTransformedStatut = listInvoices.reduce(reducerByField('status'), [])

    let invoicesTransformedProvider = listInvoices.reduce(reducerByFieldBis('provider', 'companyName'), [])
    convertReducedInvoices(invoicesTransformedProvider)

    // Create chart instance
    let chart = am4core.create("chartdivtype", am4charts.PieChart);
    chart.innerRadius = am4core.percent(40);
    chart.data = invoicesTransformedType
// Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.colors.list = [
      am4core.color("#286AA6"),
      am4core.color("#0D4F7E"),
      am4core.color("#70C9E6"),
      am4core.color("#00A3DA"),
    ];
    // Create chart instance
    let chart2 = am4core.create("chartdiv", am4charts.PieChart);
    chart2.data = invoicesTransformedStatut
    chart2.innerRadius = am4core.percent(40);
// Add and configure Series
    let pieSeries2 = chart2.series.push(new am4charts.PieSeries());
    pieSeries2.dataFields.value = "value";
    pieSeries2.dataFields.category = "category";

    pieSeries2.colors.list = [
      am4core.color("#286AA6"),
      am4core.color("#0D4F7E"),
      am4core.color("#70C9E6"),
      am4core.color("#00A3DA"),
    ];
  }

  function applyToFilter(date, list) {
    return list.filter(row => {
      return moment(row.creationdate, "YYYY-MM-DD").toDate() <= date
    })
  }

  function applyFromFilter(date, list) {
    return list.filter(row => {
      return moment(row.creationdate, "YYYY-MM-DD").toDate() >= date
    })
  }

  function applyAllFilter(from, to) {
    let list = invoices;
    if (to) {
      list = applyToFilter(to, list)
    }
    if (from) {
      list = applyFromFilter(from, list)
    }
    return list;
  }

  function convertReducedInvoices(reducedInvoices) {
    reducedInvoices.forEach(invoice => {
      if (invoice.category.length>30) {
        //l index du deuxieme espace cest la ou on divise
        const indexOfSecondSpace = invoice.category.split(' ', 2).join(' ').length;
        const part1 = invoice.category.slice(0, indexOfSecondSpace)
        const part2 = invoice.category.slice(indexOfSecondSpace, invoice.category.length)
        invoice.category = part1 + '\n' + part2
      }
    });
  }

  function changeToValue(newValue) {
    setToValue(newValue);
    const chosenYear = newValue.getFullYear();
    const chosenMonth = newValue.getMonth() + 1;
    const firstDayChosenYear = moment(chosenYear+"-01-01", "YYYY-MM-DD")
    const firstDayChosenYearPrevious = moment(chosenYear-1+"-01-01", "YYYY-MM-DD")
    const lastDayChosenYearPrevious = moment(chosenYear-1+"-12-31", "YYYY-MM-DD")
    createCharts(applyAllFilter(firstDayChosenYear, newValue))
    let firstDayMonthChosen = moment(chosenYear + "-" + chosenMonth + "-01", "YYYY-MM-DD")
    document.getElementById('dso-current').innerHTML = computeDSO(applyAllFilter(firstDayChosenYear, newValue))
    document.getElementById('dso-month').innerHTML = computeDSO(applyAllFilter(firstDayMonthChosen, newValue))
    document.getElementById('dso-previous').innerHTML = computeDSO(applyAllFilter(firstDayPreviousYear, lastDayPreviousYear))
    createChartXY(firstDayChosenYear, newValue, firstDayChosenYearPrevious, lastDayChosenYearPrevious, firstDayMonthChosen);
    setCurrentYear(chosenYear)
    computeTauxEchu(firstDayChosenYear, newValue, firstDayChosenYearPrevious, lastDayChosenYearPrevious, firstDayMonthChosen)
    InvoiceApi.getHistoryUsers(chosenYear, historyCb)
  }

  function addDelaiToInvoices(invoices) {
    let newList = []
    invoices.forEach(invoice => {
      const delai = calculerDelaiTraitement(invoice)
      const delai_max_const = constantes.filter((constante:any) => {return constante.name=='DELAY'})
      const delaiType = delai<delai_max_const[0].value? 'Dans les délais': 'Hors délai'
      newList.push({...invoice, delai: delai, delaiType: delaiType})
    });
    return newList
  }

  function reducerByField(field: string) {
    return (previousValue, currentValue) => {
      let b = false
      previousValue.forEach(x => {
        if (x.category == currentValue[field]) {
          x.value ++;
          b = true
        }
      })
      if (!b) {
        previousValue.push({category: currentValue[field], value: 1})
      }
      return previousValue;
    }
  }

  //invoice[user][provider][]
  function reducerByFieldBis(field1: string, field2: string) {
    return (previousValue, currentValue) => {
      let b = false
      previousValue.forEach(x => {
        if (x.category == currentValue['user'][field1][field2]) {
          x.value ++;
          b = true
        }
      })
      if (!b) {
        let categ = currentValue['user'][field1][field2];
        previousValue.push({category: categ, value: 1})
      }
      return previousValue;
    }
  }

  return (<div>
    <h1 style={{fontSize: '24px'}}>Indicateurs</h1>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap['fr']}>
{/*      <DatePicker
        label="Du"
        value={fromValue}
        disabled={true}
        onChange={changeFromValue}
        renderInput={(params) => <TextField {...params} />}
      />*/}
      {/*<span style={{marginRight: '10px'}}></span>*/}
      <DatePicker
        label="Date fin"
        value={toValue}
        onChange={changeToValue}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
    <Row className={'row-indicateurs'}>
      <Col xs={12} sm={12} md={6} lg={6} xl={6} className={'col-indicateurs'}>
        <div className="info-box-icon-1">
          <img src={f1} width={'100px'}></img></div>
        <div className={'titles-indic'}><div style={{fontWeight: "bold"}}>Total Factures </div>
          <span>YTD: </span>
          <span style={{fontWeight: "bold"}} id={"total-current"}></span>
          <div>
            <span>MTD: </span>
            <span style={{fontWeight: "bold"}} id={"total-month"}></span>
          </div>
          <div>
            <span>Y-1: </span>
            <span style={{fontWeight: "bold"}} id={"total-previous"}></span>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} xl={6} className={'col-indicateurs'} >
        <div className="info-box-icon-2"><img src={f2} width={'100px'}/></div>
        <div className={'titles-indic'}><div style={{fontWeight: "bold"}}>DSO </div>
          <span>YTD: </span>
          <span style={{fontWeight: "bold"}} id={"dso-current"}></span>
          <div>
            <span className={'squeeze'}>MTD: </span>
            <span style={{fontWeight: "bold"}} id={"dso-month"}></span>
          </div>
          <div>
            <span className={'squeeze'}>Y-1: </span>
            <span style={{fontWeight: "bold"}} id={"dso-previous"}></span>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} xl={6} className={'col-indicateurs'}>
        <div className="info-box-icon-3"><img src={f3} width={'100px'}></img></div>
        <BlocStats title={"Taux d'échus"} tauxReussite={tauxEchus} tauxMois={tauxEchusMois} tauxReussiteNMoinUn={tauxEchusNMoinUn}></BlocStats>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} xl={6} className={'col-indicateurs'}>
        <div className="info-box-icon-4"><img src={f4} width={'100px'}></img></div>
        <BlocStats title={'Taux de réussite'} tauxReussite={tauxReussite} tauxMois={tauxReussiteMois} tauxReussiteNMoinUn={tauxReussiteNMoinUn}></BlocStats>
      </Col>
    </Row>

    <Row style={{marginTop: "25px"}}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <h2 style={{textAlign: 'center'}}>Répartition des factures par statut</h2>
        <div id="chartdiv" style={{height: '200px'}}></div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <h2 style={{textAlign: 'center'}}>Répartition des factures par type</h2>
        <div id="chartdivtype" style={{height: '200px'}}></div>
      </Col>
    </Row>
    <Row style={{marginTop: "50px"}}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <h2 style={{textAlign: 'center', marginTop: "20px"}}>Nombre de factures Y vs Y-1</h2>
        <div id={"chartCree"} style={{height: '200px', width:'600px', marginLeft: 'auto', marginRight: 'auto'}}></div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <h2 style={{textAlign: 'center', marginTop: "20px"}}>Performance des utilisateurs</h2>
        <div id={"chartUser"} style={{height: '200px', width:'600px', marginLeft: 'auto', marginRight: 'auto'}}></div>
      </Col>
    </Row>

    </div>
  );
}
