import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import EnhancedTableHeadTable from "./TableHead";
import {Data, getComparator, headCells, headCellsProviders, Order, stableSort} from "./Constantes";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import {TablePagination} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import InvoiceApi from "./InvoiceApi";

export default function Providers(props: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState(undefined);
  let [filteredRowsExtern, setFilteredRowsExtern] = React.useState([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    InvoiceApi.getAllProviders( usersReadyExtern)
  },[])

  useEffect(() => {
    InvoiceApi.getAllProviders( usersReadyExtern)
  },[props.updatePage])

  function usersReadyExtern(res: any) {
    setFilteredRowsExtern(res)
  }

  return (<div>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" className={'table-management'}>
        <EnhancedTableHeadTable
          // numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          headCells={headCellsProviders}
          // onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={filteredRowsExtern.length}
        />
        <TableBody>
          {stableSort(filteredRowsExtern, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            // <RowMainTable key={row.id} row={row} />
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell></TableCell>
              <TableCell >{row.id}</TableCell>
              <TableCell>{row.companyName}</TableCell>
              <TableCell>{row.companyDetails}</TableCell>
              <TableCell>{row.address}</TableCell>
              {/*<TableCell>{row.rib}</TableCell>*/}
              <TableCell>{row.phone}</TableCell>
              {/*<TableCell>{showStatus(row)}</TableCell>*/}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={filteredRowsExtern.length}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage={'Nombre de lignes par page'}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </div>)
}
