import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {CREATE, PROVIDER_ROLE, VERIF} from "./Constantes";
import {useState} from "react";


export default function DeclineComment(props: any) {
    const openDialog = useSelector((state: any) => state.invoice.openDecline);
    const dispatch = useDispatch();
    const [motifRefus, setMotifRefus] = useState(undefined);

    function handleChangeMotifDeRefus(e: any) {
        setMotifRefus(e.target.value)
    }

    function toggleDialog() {
        dispatch({type: 'INVOICE_DETAIL_TOGGLE_DECLINE'})
    }

    function confirm() {
        props.refuserFacture(motifRefus)
        dispatch({type: 'INVOICE_DETAIL_TOGGLE_DECLINE'})
    }

    return (
        <Dialog open={openDialog} onClose={toggleDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {"Warning"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div>Etes vous sûr de vouloir refuser cette facture?</div>
                    <TextField
                        id="outlined-multiline-static"
                        label="Commentaire de refus"
                        multiline
                        rows={5}
                        value={motifRefus}
                        onChange={handleChangeMotifDeRefus}></TextField>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={confirm}>Confirmer</Button>
                <Button onClick={toggleDialog}>Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}