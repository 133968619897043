import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UtilFunctions from "./UtilFunctions";
import Collapse from "@mui/material/Collapse";
import {
  calculerDelaiTraitement,
  CREATE, DEVIS,
  formatNumbercell,
  IN_PROGRESS_PROVIDER, inverserDate, inverserDateTime,
  PAID,
  PAY,
  PROVIDER_ROLE,
  removeDoubleZero,
  SIGN,
  VERIF
} from "./Constantes";
import {Box, Table, TableBody, TableHead, Typography} from "@mui/material";
import InvoiceApi from "./InvoiceApi";


export default function RowMainTable(props: any) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [displayedStatus, setDosplayedStatus] = React.useState("");
  const userStorage = window.localStorage.getItem("user")
  const user = userStorage? JSON.parse(userStorage) : undefined
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const constantes = useSelector((state: any) => state.invoice.constantes)
  const delai_max_const = constantes.filter((constante:any) => {return constante.name=='DELAY'})



  React.useEffect(()=>{
     convertStatus(row.status);
  },[row]);

  function navigateToExistingInvoice() {
    dispatch({ type: "INVOICE_DETAIL", invoice: row });
    navigate('/invoice-crud/' + row.id)
  }

  function delaiTexte(row: any) {
    const delai = calculerDelaiTraitement(row)
    if (delai) return delai + ' jours'
    if (delai == 0 ) return delai + ' jours'
    return '-'
  }

  // todo optimize
  function calculerEtat(row: any) {
    if (row.status == CREATE) {
      return 'En attente de documents'
    }

    const delai = calculerDelaiTraitement(row)
    if ((delai && delai_max_const && delai<delai_max_const[0]['value']) || delai ==0) {
      return 'Dans les délais'
    } else {
      return 'Hors délais'
    }
  }



  //returns 0 for creation etc for css purposes
  function statusTransform(status: any) {
    if (status == CREATE) return 0
    if (status == VERIF&& user.userType != PROVIDER_ROLE) return 1
    if (status == SIGN && user.userType != PROVIDER_ROLE) return 2
    if (status == PAY && user.userType != PROVIDER_ROLE) return 3
    if (status == PAID) return 4
      //pour le statut en traitement provider
    else return 5
  }

  function convertStatus(status: any) {
    if (user.userType === PROVIDER_ROLE) {
      if (status === SIGN || status === VERIF || status === PAY  || status === DEVIS ){
        setDosplayedStatus( IN_PROGRESS_PROVIDER);
        return;
      } 
     
    }

    setDosplayedStatus( status); 
  }

  function showDetail() {
    return user.userType != "provider"
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          {showDetail() && <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
        {/*<TableCell >{row.id}</TableCell>*/}
        <TableCell>{row.invoiceRef}</TableCell>
        <TableCell><span onClick={navigateToExistingInvoice} className={'status-btn-' + statusTransform(row.status)}>{displayedStatus}</span></TableCell>
        <TableCell>{row.numBC}</TableCell>
        <TableCell>{UtilFunctions.dateTransform(row.creationdate)}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{row.ordonnateur}</TableCell>
        <TableCell>{removeDoubleZero(formatNumbercell(row.price))}</TableCell>
        {user.userType != PROVIDER_ROLE && <TableCell>{row.delai + ' jours'}</TableCell>}
        {user.userType != PROVIDER_ROLE && <TableCell>{calculerEtat(row)}</TableCell>}
        {user.userType != PROVIDER_ROLE && <TableCell>{row.user.provider?.companyName}</TableCell>}
        {/*<TableCell>{row.lastUser}</TableCell>*/}
      </TableRow>
      {showDetail() && <TableRow style={{padding: 0}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{paddingLeft: "10px"}}>
              <div style={{fontWeight: 'bold'}}>{row.user.provider?.companyName}</div>
              <div>{row.user.provider?.companyDetails}</div>
              <div>{row.user.provider?.address}</div>
              {/*<div>RIB: {row.user.provider?.rib}</div>*/}
              <div>{row.user.provider?.mail}</div>
              <div>{row.user.provider?.phone}</div>
            </div>
            {row.historyList.length>0 && <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" >
                Historique
              </Typography>
              <Table size="small" aria-label="purchases" className={'table-history'}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontWeight: "bold"}}>Date</TableCell>
                    <TableCell style={{fontWeight: "bold"}}>Utilisateur</TableCell>
                    <TableCell align="right" style={{fontWeight: "bold"}}>Nouveau statut</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.historyList.map((historyRow: any) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="th" scope="row">
                        {historyRow.dateTime? inverserDateTime(historyRow.dateTime): inverserDate(historyRow.dateAction)}
                      </TableCell>
                      <TableCell>{historyRow.userAction}</TableCell>
                      <TableCell align="right"><span style={{paddingRight: "10px"}}>{historyRow.nouveauStatut}</span></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>}
          </Collapse>
        </TableCell>
      </TableRow>}
    </React.Fragment>
  );
}

