// store.js
import {combineReducers, configureStore} from "@reduxjs/toolkit";

export const invoice = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      window.localStorage.setItem("user", JSON.stringify({...action.user}))
      return state
    case "SET_INVOICES":
      return {...state, invoices: action.invoices}
    case "SET_DELAY_HORS":
      return {...state, horsDelay: action.horsDelay}
    case "SET_CONSTANTES":
      return {...state, constantes: action.constantes}
    case "INVOICE_DETAIL":
      return {...state, invoice: action.invoice}
    case "INVOICE_DETAIL_TOGGLE_CONFIRMATION":
      return {...state, openConfirmation: !state.openConfirmation, invoice: action.invoice ? action.invoice: state.invoice};
    case "INVOICE_DETAIL_TOGGLE_DECLINE":
      return {...state, openDecline: !state.openDecline};
    default:
      return { invoice: {}};
  }
};

export const reducers = combineReducers({
  invoice,
});

export const store = configureStore({
  reducer: reducers,
});
