import {
  DialogContent,
  FormControlLabel, 
  LinearProgress,
  Radio,
  RadioGroup,
  TextField, 
} from "@mui/material";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";
import {useEffect} from "react";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import HorizontalLinearStepper from "./Stepper.tsx";
import {useDispatch, useSelector} from "react-redux";
import InvoiceApi from "./InvoiceApi";
import UtilFunctions from "./UtilFunctions";
import CustomizedDialogs from "./ConfirmationDialog";
import {CheckOutlined, CloseOutlined, DownloadOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {CREATE, DEVIS, PAID, PAY, PROVIDER_ROLE, SIGN, URL_INVOICE_API, VERIF} from "./Constantes";
import {Button as ButtonAnt} from 'antd';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {DeleteOutlined} from "@mui/icons-material";
import DeclineComment from "./DeclineComment";
import {store} from "./reducers";

export default function InvoiceDetail () {

  let [isNew, setIsNew] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [missingDataDialog, setMissingDataDialog] = React.useState(false);
  let [invoice, setInvoice] = React.useState({});
  let [montantTTC, setMontantTTC] = React.useState(0);
  let [initalState, setInitialState] = React.useState(true);
  let [signerClick, setSignerClick] = React.useState(false);
  let [disableSave, setDisableSave] = React.useState(false);
  let [disableSaveDevis, setDisableSaveDevis] = React.useState(true);
  const invoiceRedux = useSelector((state) => state.invoice.invoice)
  const user = JSON.parse(window.localStorage.getItem("user"))
  const providerInfo = user.provider ? user.provider.companyName + '\n' + user.provider.companyDetails + '\n' +
    user.provider.address + '\n' + user.provider.phone : '';
  let dispatch = useDispatch()
  let [bcId, setBcId] = React.useState(undefined);
  let [blId, setBlId] = React.useState(undefined);
  let [invoiceFileId, setInvoiceFileId] = React.useState(undefined);
  let [devisId, setDevisId] = React.useState(undefined);
  const  [montantSuperieurCentMille, setMontantSuperieurCentMille] = React.useState(false);
  const userType = JSON.parse(window.localStorage.getItem("user")).userType
  const navigate = useNavigate();
  const constantes = useSelector((state => state.invoice.constantes))
  const  [devisSubmitted, setDevisSubmitted] = React.useState(false);
  const  [isNewDevis, setIsNewDevis] = React.useState(false);
 
  const listDesOrdonnateurs = buildListOrdonnateur(constantes)

   const [fileLabels, setFileLabels] = React.useState({
    bDevis : undefined,
    BI : undefined,
    BC : undefined,
    BL: undefined
   })

  useEffect(()=>{ 

    const devisLimit = constantes.filter((constante) => {return constante.name=='DEVIS_LIMIT'})
  
    setMontantSuperieurCentMille(
      invoice?.price >= Number(devisLimit[0].value) && invoice?.contrat === "Nouvelle prestation"
    );

  },[invoice]);

  useEffect(() => {
    const nouveau = window.location.href.includes('new')
    setIsNew(nouveau)
    if (!nouveau) {
      setInvoice(invoiceRedux)
      setMontantTTC(formatNumbercell(invoiceRedux.price*120/100))
      InvoiceApi.getInvoiceById(invoiceRedux.id, getInvoiceCb);
    } else {
      setIsLoading(false)
      setInvoice({files: []})
    }
  }, [])

/*   useEffect(() => {
    if (!initalState) {
      changeStatus(PAY) 
    }
  }, [signerClick]) */

  function buildListOrdonnateur(consts) {
    let ordonnateurs = consts.filter(constante => constante.name.startsWith('ORDONNATEUR'))
    let transformedOrdos = ordonnateurs.map(ordo => ordo.value)
    let sortedOrdos = transformedOrdos.sort()
    let listeOrdo = []
    sortedOrdos.forEach (ordo => {
      listeOrdo.push(<MenuItem value={ordo}>{ordo}</MenuItem>)
    })
    return listeOrdo

  }
 

  function refuserFacture(motif) {
      const invoiceWithProvider = {...invoice, provider: {id: user.providerId}, motifDeRefus: motif};
      InvoiceApi.modifyInvoice({...invoiceWithProvider, status: CREATE, lastUser: user.username}, createInvoiceCallBack)
  }

  function saveInvoiceCb(returnedInvoice) {
    setInvoice(returnedInvoice)
    dispatch({ type: "INVOICE_DETAIL", invoice: returnedInvoice });
  }

  function getInvoiceCb(res) {
    setIsLoading(false)
    setInvoice(res)
    
   

    if (res.files && res.files.length > 0) {

      const fileNames = {
                          bDevis : undefined,
                          BI : undefined,
                          BC : undefined,
                          BL: undefined
                        };

     
      const bc = res.files.find(file => {
        return file.fileTypeBk == 'bc';
      })
      
       
      if (bc?.fileId) {
        fileNames["BC"] = bc.fileName;
   
        setBcId(bc.fileId)
      }
     
      const bl = res.files.find(file => {
        return file.fileTypeBk == 'bl';
      })
      if (bl?.fileId) {
        

       fileNames["BL"] = bl.fileName; 
        setBlId(bl.fileId)
      }
   
      const invoiceFile = res.files.find(file => {
        return file.fileTypeBk == 'invoice';
      })
 
      if (invoiceFile?.fileId) { 

      fileNames["BI"] = invoiceFile.fileName;  
        setInvoiceFileId(invoiceFile.fileId)
      }

      
      
      const devis = res.files.find(file => {
        return file.fileTypeBk == 'devis';
      })
 
      if (devis?.fileId) {
        let fileNameDevis = document.getElementById('file-name-devis');
       
        if (fileNameDevis) {
          fileNames["bDevis"] = devis.fileName; 
        }
        setDevisId(devis.fileId)
      } 
      setFileLabels(fileNames)
    }
  }

  const handleChangeOrdonnateur = (event) => {
    setInvoice({...invoice, ordonnateur: event.target.value})
  };

  const handleChangeSelectedFileBc = (event) => {
    let file = event.target.files[0];
    if (file) { 
      setFileLabels(old =>({
        ...old,
        BC : event.target.files[0].name
      }))
      InvoiceApi.uploadFile(file, 'bc', uploadCallbackBc);
    }
  }

  const handleChangeSelectedFileBl = (event) => {
    let file = event.target.files[0];
    if (file) { 
      setFileLabels(old =>({
        ...old,
        BL : event.target.files[0].name
      }))
      InvoiceApi.uploadFile(file, 'bl', uploadCallbackBl);
    }
  }

  const handleChangeSelectedFileInvoice = (event) => {
    let file = event.target.files[0];
    if (file) { 
      setFileLabels(old =>({
        ...old,
        BI : event.target.files[0].name
      }))
      InvoiceApi.uploadFile(file, 'invoice', uploadCallbackInvoice);
    }
  }

  const handleChangeSelectedFileDevis = (event) => {
    let file = event.target.files[0];
    if (file) { 
      setFileLabels(old =>({
        ...old,
        bDevis : event.target.files[0].name
      }))
      InvoiceApi.uploadFile(file, 'devis', uploadCallbackDevis);
    }
  }

  function handleChangeType(e) {
    setInvoice({...invoice, type: e.target.value})
  }

  function handleChangeNumBc(e) {
    setInvoice({...invoice, numBC: e.target.value})
  }

  function handleChangeInvoiceRef(e) {
    setInvoice({...invoice, invoiceRef: e.target.value})
  }

  function handleChangeReceiver(e) {
    setInvoice({...invoice, receiver: e.target.value})
  }

  function handleChangeDescription(e) {
    setInvoice({...invoice, description: e.target.value})
  }

  function handleChangeMontant(e) {
    const text = e.target.value
    if (!isNaN(text)) {
      setInvoice({...invoice, price: text})
      setMontantTTC(formatNumbercell(text*120/100))
    } else {
      setInvoice({...invoice, price: text.substring(0, text.length-1)})
    }
  }

  function handleChangeIce(e) {
    const text = e.target.value
    if (!isNaN(text)) {
      setInvoice({...invoice, ice: text})
    } else {
      setInvoice({...invoice, ice: text.substring(0, text.length-1)})
    }
  }

  function formatNumbercell (num) {
    return num
      ? num
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
      : num;
  };

  const isNotValidInputs = () =>{
    const isNewPrestation = invoice.contrat == "Nouvelle prestation"
    if(!invoice.contrat || !invoice.type || !invoice.ordonnateur || !invoice.numBC ) return true;
    if(!invoice.invoiceRef || !invoice.price || !invoice.ice || !invoice.description ) return true;
    if(!invoiceFileId) return true;
    if((!bcId ||!blId ) && isNewPrestation) return true; 

    return false;
  }

  const toggleMissingDataDialogAndSave = () =>{
    setMissingDataDialog(!missingDataDialog)

    const invoiceWithUser = {...invoice, user: {id: user.id}};
    const today = new Date().toISOString().substring(0,10) 

    setDisableSave(false);
    if (!invoiceRedux.id) {
      InvoiceApi.createInvoice({...invoiceWithUser, status: CREATE, creationdate: today, receiver: 'BMCE Capital\nTOUR BMCE rond point HASSAN 2\nCasablanca', lastUser: user.username}, saveInvoiceCb)
    }
    else {
      InvoiceApi.modifyInvoice({...invoiceWithUser, lastUser: user.username}, saveInvoiceCb)
    }
  }

  function save(e) {
    setInitialState(false)
    setDisableSave(true)
    let invoiceWithUser = {...invoice, user: {id: user.id}};
    const today = new Date().toISOString().substring(0,10) 
 
    if (isNotValidInputs()) {
      setMissingDataDialog(true);
    } else {
      let lclStatus = VERIF;

      if(e.target.name ===  "enregistrer-invoice" && userType=='provider' && montantSuperieurCentMille){
        lclStatus = DEVIS;
      }
 
      if(e.target.name === "submit-devis" && (userType=='DSI' || userType=='MG')  && montantSuperieurCentMille){
        setDevisSubmitted(true); 
      } 
      if (!invoiceRedux.id) {
        InvoiceApi.createInvoice(
          {
            ...invoiceWithUser, 
            status: lclStatus, 
            creationdate: today, 
            receiver: 'BMCE Capital\nTOUR BMCE rond point HASSAN 2\nCasablanca', 
            lastUser: user.username
          }, createInvoiceCallBack)
      }
      else {
        InvoiceApi.modifyInvoice(
          {
            ...invoiceWithUser, 
            status: lclStatus, 
            lastUser: user.username
          }, createInvoiceCallBack)
      }
    }
  }

  function createInvoiceCallBack(res) {
    console.log("after resp", res);
    setInvoice(res)
    dispatch({ invoice: res, type: "INVOICE_DETAIL_TOGGLE_CONFIRMATION" })
  }

  function uploadCallbackBc(res) {
    const uriFile = res.fileUri
    const fileId = uriFile.slice(uriFile.indexOf('download') + 9, 100)
    setBcId(fileId)
    setInvoice({...invoice, files: [...invoice.files, {fileId: fileId}]});
  }

  function uploadCallbackBl(res) {
    const uriFile = res.fileUri
    const fileId = uriFile.slice(uriFile.indexOf('download') + 9, 100)
    setBlId(fileId)
    setInvoice({...invoice, files: [...invoice.files, {fileId: fileId}]});
  }

  function uploadCallbackInvoice(res) {
    // const fileId = res.fileUri?.split('/')[4]
    const uriFile = res.fileUri
    const fileId = uriFile.slice(uriFile.indexOf('download') + 9, 100)
    setInvoiceFileId(fileId)
    setInvoice({...invoice, files: [...invoice.files, {fileId: fileId}]});
  }

  function uploadCallbackDevis(res) {
    const uriFile = res.fileUri
    const fileId = uriFile.slice(uriFile.indexOf('download') + 9, 100)
    setDevisId(fileId)
    setIsNewDevis(true); 
    setDisableSaveDevis(false);
    
    setInvoice({...invoice, files: [...invoice.files, {fileId: fileId}]});
  }

  function backToDashboard() {
    navigate('/dashboard');
  }

  function valider() {
    setDisableSave(false)
    setInitialState(false);
    signer(); 
  }

  function refuser() {
    dispatch({type: 'INVOICE_DETAIL_TOGGLE_DECLINE'})
  }

  function signer() {
    changeStatus(PAY)
  }

  function payer() {
    changeStatus(PAID)
  }

  function enAtttenteDevis() {
    changeStatus(DEVIS)
  }

  function changeStatus(status) {
    setDisableSave(true)
    const invoiceWithProvider = {...invoice, provider: {id: user.providerId}};
    InvoiceApi.modifyInvoice({...invoiceWithProvider, status: status, lastUser: user.username}, createInvoiceCallBack)
  }

  function bcUpload() {
    document.getElementById('input-file-bc').click();
  }

  function blUpload() {
    document.getElementById('input-file-bl').click();
  }

  function invoiceUpload() {
    document.getElementById('input-file-invoice').click();
  }

  function devisUpload() { 
    document.getElementById('input-file-devis').click();
   
  }

  function devisDownload() {
  

    InvoiceApi.downloadFile(URL_INVOICE_API + '/file/download/' + devisId, document.getElementById('file-name-devis').innerText)
  }

  function invoiceDownload() {
    InvoiceApi.downloadFile(URL_INVOICE_API + '/file/download/' + invoiceFileId, document.getElementById('file-name-invoice').innerText)
  }

  function blDownload() {
    InvoiceApi.downloadFile(URL_INVOICE_API + '/file/download/' + blId, document.getElementById('file-name-bl').innerText)
  }

  function bcDownload() {
    InvoiceApi.downloadFile(URL_INVOICE_API + '/file/download/' + bcId, document.getElementById('file-name-bc').innerText)
  }

  function toggleMissingDataDialog() {
    setMissingDataDialog(!missingDataDialog)
    setDisableSave(false);
  }

 

  function changeContract(e){
    setInvoice({...invoice, contrat: e.target.value})
  }

  function showDevis() { 
 
    return (invoiceRedux.status == DEVIS && (userType=='DSI' || userType=='MG' || userType=='admin') && montantSuperieurCentMille);
  }

  function clickDeleteInvoiceFile() {
    setInvoiceFileId(undefined) 
    setFileLabels(old =>({
      ...old,
      BI: ""
    }))
    InvoiceApi.deleteFile(invoiceFileId)
    const filesWithoutTheDeleted = invoice.files.filter(file=> {
      return file.fileId != invoiceFileId;
    })
    setInvoice({...invoice, files: filesWithoutTheDeleted})
  }

  function clickDeleteBC() {
    setBcId(undefined) 
    setFileLabels(old =>({
      ...old,
      BC: ""
    }))
    InvoiceApi.deleteFile(bcId)
    const filesWithoutTheDeleted = invoice.files.filter(file=> {
      return file.fileId != bcId;
    })
    setInvoice({...invoice, files: filesWithoutTheDeleted})
  }

  function clickDeleteBL() {
    setBlId(undefined)
    setFileLabels(old =>({
      ...old,
      BL: ""
    }))
    InvoiceApi.deleteFile(blId)
    const filesWithoutTheDeleted = invoice.files.filter(file=> {
      return file.fileId != blId;
    })
    setInvoice({...invoice, files: filesWithoutTheDeleted})
  }

  function clickDeleteDevis() {
    setDevisId(undefined)
  
    setDisableSaveDevis(true); 
    setFileLabels(old =>({
      ...old,
      bDevis: ""
    }))
    InvoiceApi.deleteFile(devisId)
    const filesWithoutTheDeleted = invoice.files.filter(file=> {
      return file.fileId != devisId;
    })
    setInvoice({...invoice, files: filesWithoutTheDeleted})
  }

  function modeCreationInvoiceAndUserProvider () {
    return (invoiceRedux.status == CREATE || !invoiceRedux.status) && userType == PROVIDER_ROLE;
  }

  const canRefuse = () =>{

    if( invoiceRedux.status === VERIF ){
      if( userType === 'SF' && (!montantSuperieurCentMille || (montantSuperieurCentMille && devisId))) return true;
      if(montantSuperieurCentMille && (userType === 'DSI' || userType === 'MG') && !devisId && showDevis()) return true;
      if(userType === 'admin') return true;
    }
    if( invoiceRedux.status === PAY ){
      if( userType === 'SF' || userType === 'admin') return true;
    }
    return false;
  } 

  return (
    <div>
      <HorizontalLinearStepper ></HorizontalLinearStepper>
      {isNew && <h2 className={'title-top'}>Nouvelle facture</h2>}
      {!isNew && <h2>Modifier facture</h2>}
      <fieldset name={'invoice-form'} >
        <Box
          // component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '50ch' },
          }}
          noValidate
          autoComplete="off">
          <div>
            {!initalState && !invoice.contrat && <div className={'mandatory'}>
              Ce champs est obligatoire
            </div>}
            <div>
              <FormControl style={{marginLeft: "8px", marginBottom: "8px"}} disabled={(invoiceRedux.status && invoiceRedux.status != CREATE) || userType != PROVIDER_ROLE} error={!invoice.contrat && !initalState}>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" onChange={changeContract} value={invoice.contrat} defaultValue={invoiceRedux.contrat}>
                  <FormControlLabel value="Maintenance" control={<Radio />} label="Maintenance" />
                  <FormControlLabel value="Nouvelle prestation" control={<Radio />} label="Nouvelle prestation" />
                  <FormControlLabel value="Abonnement" control={<Radio />} label="Abonnement" />
                </RadioGroup>
              </FormControl>
            </div>
            <FormControl sx={{ m: 1, minWidth: '50ch' }}>
              <InputLabel id="demo-simple-select-helper-label">Type de la prestation *</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                value={invoice.type}
                defaultValue={invoiceRedux.type}
                label="Type de la prestation *"
                error={!invoice.type && !initalState}
                onChange={handleChangeType}
                disabled={(invoiceRedux.status && invoiceRedux.status != CREATE) || userType != PROVIDER_ROLE}>
                <MenuItem value={'DSI'}>Direction des systèmes d'information</MenuItem>
                <MenuItem value={'Moyens généraux'}>Moyens généraux</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: '50ch' }}>
              <InputLabel id="demo-simple-select-helper-label">Ordonnateur *</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={invoice.ordonnateur}
                label="Ordonnateur"
                error={!invoice.ordonnateur && !initalState}
                defaultValue={invoiceRedux.ordonnateur}
                onChange={handleChangeOrdonnateur}
                disabled={(invoiceRedux.status && invoiceRedux.status != CREATE) || userType != PROVIDER_ROLE}>
                {listDesOrdonnateurs}
              </Select>
            </FormControl>
            <TextField
              id="outlined-helperText"
              label="Numéro bon de commande"
              required
              value={invoice.numBC}
              error={!invoice.numBC && !initalState}
              onChange={handleChangeNumBc}
              disabled={(invoiceRedux.status && invoiceRedux.status != CREATE) || userType != PROVIDER_ROLE}/>
            <TextField
              id="outlined-helperText"
              label="Référence facture"
              required
              value={invoice.invoiceRef}
              error={!invoice.invoiceRef && !initalState}
              onChange={handleChangeInvoiceRef}
              disabled={(invoiceRedux.status && invoiceRedux.status != CREATE) || userType != PROVIDER_ROLE}/>
          </div>
          <div>
            <TextField
              id="outlined-helperText-2"
              label="Montant hors taxes"
              required
              value={invoice.price}
              onChange={handleChangeMontant}
              error={!invoice.price && !initalState}
              disabled={(invoiceRedux.status && invoiceRedux.status != CREATE) || userType != PROVIDER_ROLE}
            />
            <TextField disabled variant="filled" label='Montant TTC' value={montantTTC}></TextField>
            <TextField
                id="outlined-helperText-1"
                label="ICE"
                required
                value={invoice.ice}
                onChange={handleChangeIce}
                error={!invoice.ice && !initalState}
                disabled={(invoiceRedux.status && invoiceRedux.status != CREATE) || userType != PROVIDER_ROLE}
            />
          </div>
          <div>
            <TextField
              id="outlined-multiline-static"
              label="Fournisseur"
              multiline
              rows={5}
              disabled
              variant={"filled"}
              value={invoiceRedux?.user?.provider? invoiceRedux.user.provider.companyName + '\n' + invoiceRedux.user.provider.companyDetails + '\n' +
                invoiceRedux.user.provider.address + '\n' + invoiceRedux.user.provider.phone: providerInfo}/>
            {(invoiceRedux.status && invoiceRedux.status != CREATE) && invoice.receiver &&<TextField
              id="outlined-multiline-static"
              label="Adressé à"
              required
              multiline
              rows={5}
              value={invoice.receiver}
              error={!invoice.receiver && !initalState}
              onChange={handleChangeReceiver}
              disabled/> }
            <TextField
              id="outlined-multiline-static"
              label="Description"
              required
              multiline
              rows={5}
              value={invoice.description}
              error={!invoice.description && !initalState}
              onChange={handleChangeDescription}
              disabled={(invoiceRedux.status && invoiceRedux.status != CREATE) || userType != PROVIDER_ROLE }/>
            {invoiceRedux.motifDeRefus && <TextField
              id="outlined-multiline-static"
              label="Commentaire de refus"
              multiline
              rows={5}
              value={invoice.motifDeRefus}
              disabled={true}/>}
          </div>
          <div>
            {(invoiceRedux.status != CREATE && invoiceRedux.status) && <TextField
              id="outlined-helperText"
              label="Date de création"
              disabled
              variant={'filled'}
              value={UtilFunctions.dateTransform(invoice.creationdate)}/>}
          </div>

          <div className={'upload-files'}>
            <div>
              {/*<h3 style={{paddingLeft: "10px"}}>Checklist</h3>*/}
              <table className={'table-checklist'}>
                <tr>
                  <td style={{fontWeight: "bold"}}>Facture</td>
                  <td>
                    {!invoiceFileId && <CloseOutlined style={{color:"#8B0000"}}></CloseOutlined>}
                    {invoiceFileId && <CheckOutlined  style={{color:"#006400"}}></CheckOutlined>}
                  </td>
                  <td className={'upload-btn'}>
                    {modeCreationInvoiceAndUserProvider() && !invoiceFileId &&
                      <ButtonAnt type="primary" shape="round" size={'large'} className={'upload-bc'} icon={<DownloadOutlined />} onClick={invoiceUpload}>Upload</ButtonAnt>}
                    <a   id={'file-name-invoice'} onClick={invoiceDownload} className={'bc-title'}>{fileLabels.BI}</a>
                    {modeCreationInvoiceAndUserProvider() && invoiceFileId &&
                      <div className={'delete-icon'} ><DeleteOutlined onClick={clickDeleteInvoiceFile} /></div>}
                  </td>
                </tr>
               {
                 invoice.contrat == "Nouvelle prestation" && <>
                  <tr>
                    <td style={{fontWeight: "bold"}}>BC</td>
                    <td>
                      {!bcId && <CloseOutlined style={{color:"#8B0000"}}></CloseOutlined>}
                      {bcId && <CheckOutlined  style={{color:"#006400"}}></CheckOutlined>}
                    </td>
                    <td className={'upload-btn'}>
                      {(invoiceRedux.status == CREATE || !invoiceRedux.status) && userType == PROVIDER_ROLE && !bcId &&
                        <ButtonAnt type="primary" shape="round" size={'large'} className={'upload-bc'} icon={<DownloadOutlined />} onClick={bcUpload}>Upload</ButtonAnt>}
                      <a  id={'file-name-bc'} onClick={bcDownload} className={'bc-title'}>{fileLabels.BC}</a>
                      {modeCreationInvoiceAndUserProvider() && bcId &&
                        <div className={'delete-icon'} ><DeleteOutlined onClick={clickDeleteBC} /></div>}
                    </td>
                  </tr> 
                  <tr>
                      <td style={{fontWeight: "bold"}}>BL</td>
                      <td>
                        {!blId && <CloseOutlined style={{color:"#8B0000"}}></CloseOutlined>}
                        {blId && <CheckOutlined  style={{color:"#006400"}}></CheckOutlined>}
                      </td>
                      <td className={'upload-btn'}>
                        {(invoiceRedux.status == CREATE || !invoiceRedux.status) && userType == PROVIDER_ROLE && !blId &&
                        <ButtonAnt type="primary" shape="round" size={'large'} className={'upload-bc'} icon={<DownloadOutlined />} onClick={blUpload}>Upload</ButtonAnt>}
                        <a  id={'file-name-bl'} onClick={blDownload} className={'bc-title'}>{fileLabels.BL}</a>
                        {modeCreationInvoiceAndUserProvider() && blId &&
                          <div className={'delete-icon'} ><DeleteOutlined onClick={clickDeleteBL} /></div>}
                      </td>
                    </tr>
                </>
               }
               
                {(montantSuperieurCentMille && userType != PROVIDER_ROLE) &&
                  <tr>
                    <td style={{fontWeight: "bold"}}>Devis contradictoire</td>
                    <td>
                      {!devisId && <CloseOutlined style={{color:"#8B0000"}}></CloseOutlined>}
                      {devisId && <CheckOutlined  style={{color:"#006400"}}></CheckOutlined>}
                    </td>
                    <td className={'upload-btn'}>
                       
                      {((!devisId || devisId === undefined || devisId === 'undefined') && showDevis())  && <ButtonAnt type="primary" shape="round" size={'large'} className={'upload-bc'} icon={<DownloadOutlined />} onClick={devisUpload}>Upload</ButtonAnt>}
                      <a  id={'file-name-devis'} className={'bc-title'} onClick={devisDownload}>{fileLabels.bDevis}</a>
                      {( showDevis() && ((!devisSubmitted  && !devisId && montantSuperieurCentMille && isNewDevis) ||(!devisSubmitted && devisId && isNewDevis)) )  && 
                        <div className={'delete-icon'} ><DeleteOutlined onClick={clickDeleteDevis} /></div>}
                    </td>
                  </tr>
                }
              </table>
              <div>
                <input type="file" onChange={handleChangeSelectedFileBc} id={'input-file-bc'} style={{display: "none"}}/>
                <input type="file" onChange={handleChangeSelectedFileBl} id={'input-file-bl'} style={{display: "none"}}/>
                <input type="file" onChange={handleChangeSelectedFileInvoice} id={'input-file-invoice'} style={{display: "none"}}/>
                <input type="file" onChange={handleChangeSelectedFileDevis} id={'input-file-devis'} style={{display: "none"}}/>
              </div>
            </div>
          </div>
          {isLoading && <Box sx={{ width: '100%' }} style={{marginBottom: "10px"}}>
            <LinearProgress />
          </Box>}
        </Box>
      </fieldset>
      <div className={'center-buttons'}>
        {(invoiceRedux.status == CREATE || !invoiceRedux.status) && userType == 'provider' 
        && <Button name="enregistrer-invoice" variant="contained" className={'boutons-en-bas'} onClick={save} disabled={disableSave}>
          Enregistrer</Button>}
        {(invoiceRedux.status == VERIF  
        && (userType == 'SF' || userType == 'admin')) &&
          <Button variant="contained" color="success" className={'boutons-en-bas'} onClick={valider} disabled={disableSave}>
            Valider</Button> }
        
        { showDevis() &&
          <Button variant="contained" color="success" name="submit-devis" className={'boutons-en-bas'} onClick={save}>
            Ajouter devis contradictoire</Button> }
        {
        canRefuse() 
        && <Button variant="contained" className={'boutons-en-bas-2'} disabled={disableSave} onClick={refuser}>Refuser</Button> }
        {invoiceRedux.status=== SIGN && (userType === 'SF' || userType === 'admin') && <Button variant="contained" color="success" 
        disabled={disableSave} className={'boutons-en-bas'} onClick={signer}>Signer</Button>}
        {invoiceRedux.status === PAY && (userType === 'SF' || userType === 'admin') && <Button variant="contained" color="success" 
      className={'boutons-en-bas'} 
        onClick={payer}>Déclarer comme payé</Button>}
        <Button variant="contained" className={'boutons-en-bas-2'} onClick={backToDashboard}>Retour</Button>
      </div>
      <CustomizedDialogs></CustomizedDialogs>
      <Dialog open={missingDataDialog} onClose={toggleMissingDataDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Warning"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">  
          Veuillez compléter le formulaire avec les informations obligatoires {
          (!bcId && invoice.contrat === "Nouvelle prestation" 
          || !blId && invoice.contrat === "Nouvelle prestation" || !invoiceFileId)?  
          ' et les pièces justificatives montionnées ci-après : ' : '.'} 
            {(!bcId || !blId || !invoiceFileId) &&
              <div>
                {!bcId && invoice.contrat === "Nouvelle prestation" && <li>Le bon de commande</li>}
                {!blId && invoice.contrat === "Nouvelle prestation" && <li>Le bon de livraison</li>}
                {!invoiceFileId && <li>La facture</li>}
              </div>
            } 
            <br/>
          Voudriez-vous quand même enregistrer la facture entant que Draft et la compléter ultérieurement?
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={toggleMissingDataDialogAndSave}>Enregistrer comme Draft</Button>
          <Button onClick={toggleMissingDataDialog} >Annuler</Button>
        </DialogActions>
      </Dialog>
      <DeclineComment refuserFacture={refuserFacture}></DeclineComment>
    </div>
  )
}
