import logo from './logo.png';
import imagelogo from './imagelogo.png';
import './App.css';
import 'antd/dist/antd.css';
import {Button, Layout, Menu} from 'antd';
import {
  DashboardOutlined,
  HddOutlined,
  PoweroffOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {Link, Outlet, useNavigate} from "react-router-dom";
import { useLayoutEffect, useState} from "react";
import {useSelector} from "react-redux";
const { SubMenu } = Menu;


const { Header, Sider, Content } = Layout;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function App() {
  const user = JSON.parse(window.localStorage.getItem("user"))
  const navigate = useNavigate();
  const [width, height] = useWindowSize();
  const horsDelay = useSelector(state => state.invoice.horsDelay)

  function logOut () {
    window.localStorage.removeItem("user");
    navigate('/')
    window.location.reload()
  }

  function formatUserType(type) {
    if(type== 'MG') return 'Moyens généraux'
    if(type== 'provider') return 'Fournisseur'
    if(type== 'SF') return 'Services financiers'
    return type
  }

  function mobileMode() {
    return width < 600
  }

  function clickDash() {
    navigate('/dashboard');
  }

  return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={mobileMode()}>
          <div className="logo" />
          <img src={logo} width={200} className="img-logo"/>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
            <SubMenu key="2" icon={<DashboardOutlined />} title="Dashboard" onTitleClick={clickDash}>
              <Menu.Item key="2"><Link to="/dashboardRetard">Hors délai ({horsDelay})</Link></Menu.Item>
              <Menu.Item key="3"><Link to="/dashboardCours">En cours</Link></Menu.Item>
              <Menu.Item key="10"><Link to="/dashboardClosed">Payées</Link></Menu.Item>
            </SubMenu>
            {user.userType != 'provider' && <Menu.Item key="4" icon={<HddOutlined />}>
                <Link to="/statistics">Indicateurs</Link>
            </Menu.Item>}
            <Menu.Item key="5" icon={<UserOutlined />}>
              <Link to="/myaccount">Mon Compte</Link>
            </Menu.Item>
            {(user.userType == 'admin' || user.userType == 'SF') && <Menu.Item key="6" icon={<UnorderedListOutlined />}>
              <Link to="/usermanagement">Gestion des utilisateurs</Link>
            </Menu.Item>}
            {(user.userType == 'admin' || user.userType == 'SF') && <Menu.Item key="7" icon={<UnorderedListOutlined />}>
                <Link to="/requests">Gestion des fournisseurs</Link>
            </Menu.Item>}
          </Menu>
          <img src={imagelogo} width={200} className="img-logo-bmce"/>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 , textAlign: "center", color: 'white', userSelect: 'none'}}>
              <Button style={{float: "right", marginTop: '17px', marginRight: "30px"}} type={'primary'} className={'log-out-button'} onClick={logOut} shape={'circle'} icon={<PoweroffOutlined />}></Button>
              <span className={'invoice-title'}>INVOICE MANAGER</span>
              <span style={{float: "right", fontSize: '20px', marginRight: "30px"}}>{user.username + ', ' + formatUserType(user.userType)}</span>
          </Header>
          <Content
              className="site-layout-background"
              style={{
                padding: '12px 20px',
                // padding: 24,
                minHeight: 280,
              }}
          >
              <Outlet></Outlet>
          </Content>
        </Layout>

      </Layout>
  );
}

export default App;
