import logo from "./z.png";
import * as React from "react";
import {Button as ButtonAnt, Button, Input} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import InvoiceApi from "./InvoiceApi";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import {DialogContent} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
 

export default function ReinitPassword() {
  let [form, setForm] = useState<any>(
    {
      
    })
  const [initialState, setInitialState] = React.useState(true);
  const [pending, setpending] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const [status, setStatus] = React.useState("FORM");
  const [showUserExistError, setShowUserExistError] = React.useState(true);
  const [showConfirmationDialog3, setShowConfirmationDialog3] = React.useState(false);
  let navigate = useNavigate()
   
 
  useEffect(()=>{
    let interval = null;
    if(counter > 0){
      interval = setInterval(()=>{
        setCounter(old => old -1);
      },1000);
    }

    return ()=> clearInterval(interval);
  },[counter]);
 

  function login() {
    navigate('/')
  }

  
  function userNameChange(e: any) {
    setForm({...form, username: e.target.value})
  }
 
  function sendRequest( callback : any ) {
    const text = form.username
   
    const validEmail = testMail(text)
 
    if (validEmail) {
      setpending(true)
      InvoiceApi.getUserByUsername(text, (user : any)=> {
        setpending(false);
        setShowUserExistError(true);
        
        if(callback){
          callback(user);
        }
      

      }, ()=> {  setpending(false); setShowUserExistError(false)})
    }else{
      setShowUserExistError(false);
    }
  }

  function testMail(mail: any) {
    return mail && mail.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  function isUsernameInValid() {
    const validEmail = testMail(form.username)
    return !showUserExistError || (!initialState && !form.username) || (!initialState && !validEmail)
  }

  function reinitPassword(){

    sendRequest(
      (user : any)=>{
        InvoiceApi.resetPassword( user , (user1 : any)=>{ 
          setStatus("RESET_COMPLETED");
          setCounter(60);
        })
      }
    );
  }
 
  return (
    <div className={'login-page'}>
      <div className={'signup-box'}>
        <img src={logo} className={'signup-logo'}/>
        <h2 className={'signup-title'}>Réinitialiser votre mot de passe.</h2>
       {
        status === "FORM" &&  <div><Input placeholder="E-mail" className={isUsernameInValid()? 'invalid-input':'signup-user'} onChange={userNameChange} onBlur={()=>sendRequest(null)}/></div>
       }
        {
        status === "RESET_COMPLETED" &&  <div style={{color: "white", marginBottom: "10px"}}>
          {`Le mail de réinitialisation a été envoyer avec succès à "${form.username}". Merci de vérifier votre boîte de réception.`}
          <br/>
          <a onClick={counter === 0 ? ()=> setStatus("FORM") : ()=>{}}>{`Changer le nom d'utilisateur. ${counter === 0 ? "": `( ${counter}s )`}`}</a>
          </div>
       }
         
        {  !showUserExistError && <div style={{color: "red", marginBottom: "10px"}}>Ce nom d'utilisateur est invalid.</div>}
 
          <Button className={'signup-user'} onClick={reinitPassword} disabled={pending || counter !== 0}>
 
            {
               status === "FORM" && "Réinitialiser le mot de passe"
            }
            {
               status === "RESET_COMPLETED" && `"Re-envoyer le mail de réinitialisation ${counter === 0 ? "": `( ${counter}s )`}`
            }
          </Button> 
          <div className={'sign-up'}>
            Revenir à la page d'authenfication.<a onClick={login}> S'identifier</a>*
          </div>
      </div>
 
    </div>
  );
}


