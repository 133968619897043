import logo from "./z.png";
import * as React from "react";
import {Button as ButtonAnt, Button, Input} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import InvoiceApi from "./InvoiceApi";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import {DialogContent} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import {DownloadOutlined} from "@ant-design/icons";
import {URL_INVOICE_API} from "./Constantes";

export default function SignUp() {

  let navigate = useNavigate()

  //todo secure status in backend
  let [form, setForm] = useState<any>({userStatus: 'WAITING_APPROVAL', userType: 'provider'})
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  const [showUserExistError, setShowUserExistError] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = React.useState(true);
  const [initialState, setInitialState] = React.useState(true);
  const [ribUploaded, setRibUploaded] = React.useState(false);
  const [showConfirmationDialog2, setShowConfirmationDialog2] = React.useState(false);
  const [showConfirmationDialog3, setShowConfirmationDialog3] = React.useState(false);
  const [passwordInitMode, setPasswordInitMode] = React.useState(false);
  const [user, setUser] = React.useState<any>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const isReinit = searchParams.get("reinit")

  useEffect(() => {
    const userId = searchParams.get("userId")
    const token = searchParams.get("token")
    if (userId && token) {
      setPasswordInitMode(true)
      window.localStorage.setItem("user", JSON.stringify({token: token}))
      InvoiceApi.getUserById(userId, userCb)
    }
  }, [])

  function userCb(res: any) {
    if (res.password && !isReinit) {
      toggleDialog3();
    } else {
      setUser(res)
    }
  }

  function toggleDialog() {
    setShowConfirmationDialog(!showConfirmationDialog)
  }

  function toggleDialog2() {
    setShowConfirmationDialog2(!showConfirmationDialog2)
  }

  function toggleDialog3() {
    setShowConfirmationDialog3(!showConfirmationDialog3)
  }

  function redirectLogin() {
    toggleDialog3();
    navigate('/')
  }

  function redirectLogin2() {
    toggleDialog2()
    navigate('/')
  }

  function signup() {
    setInitialState(false)
    const validEmail = testMail(form.username)
    if (form.firstName && form.lastName && form.username && form.providerText && validEmail && !showUserExistError) {
      InvoiceApi.createUserPublic(form, signupCb)
    }
  }

  function createPassword() {
    if (form.password == form.passwordConfirmation) {
      setPasswordMatch(true)

      InvoiceApi.modifyPassword({...user, password: form.password}, setPasswordCb)
    } else {
      setPasswordMatch(false)
    }
  }

  function setPasswordCb () {
    setShowConfirmationDialog2(true)
  }

  function signupCb() {
    toggleDialog()
    document.getElementById('rib-name').innerText = ''
    setForm({...form, ribFile: undefined})
  }

  function login() {
    navigate('/')
  }

  function firstNameChange(e: any) {
    setForm({...form, firstName: e.target.value})
  }

  function lastNameChange(e: any) {
    setForm({...form, lastName: e.target.value})
  }

  function userNameChange(e: any) {
    setForm({...form, username: e.target.value})
  }

  function passwordChange(e: any) {
    setForm({...form, password: e.target.value})
  }

  function passwordConfirmationChange(e: any) {
    setForm({...form, passwordConfirmation: e.target.value})
  }

  function providerChange(e: any) {
    setForm({...form, providerText: e.target.value})
  }

  function sortDuField(e: any) {
    const text = e.target.value
    const validEmail = testMail(text)
    if (validEmail) {
      InvoiceApi.getUserByUsername(text, ()=> {setShowUserExistError(true)}, ()=> {setShowUserExistError(false)})
    }
  }

  function testMail(mail: any) {
    return mail && mail.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  function isUsernameInValid() {
    const validEmail = testMail(form.username)
    return showUserExistError || (!initialState && !form.username) || (!initialState && !validEmail)
  }

  function ribUpload(){
    document.getElementById('input-file-rib').click();
  }

  function handleChangeSelectedFileRib(event){
    let file = event.target.files[0];
    if (file) {
      document.getElementById('rib-name').innerText = event.target.files[0].name;
      InvoiceApi.uploadFile(file, 'rib', uploadCb);
    }
  }

  function uploadCb(res) {
    const uriFile = res.fileUri
    const fileId = uriFile.slice(uriFile.indexOf('download') + 9, 100)
    setForm({...form, ribFile: {fileId: fileId}})
    setRibUploaded(true)
  }

  function downloadRib() {
    InvoiceApi.downloadFile(URL_INVOICE_API + '/file/download/' + form.ribFile?.fileId, document.getElementById('rib-name').innerText)
  }

  return (
    <div className={'login-page'}>
      <div className={'signup-box'}>
        <img src={logo} className={'signup-logo'}/>
        <h2 className={'signup-title'}>Gérez facilement vos factures en ligne avec un meilleur suivi</h2>
        {passwordInitMode && user && <div>
          <div style={{color: "white"}}>Bonjour {user?.firstName + ' ' + user?.lastName}, veuillez {! isReinit && <span>finaliser votre inscription</span>}
            {isReinit && <span>réinitialiser votre mot de passe</span>}</div>
          <div><Input placeholder="Choisir mot de passe" type={'password'} className={'signup-user'} onChange={passwordChange}/></div>
          <div><Input placeholder="Confirmer mot de passe" type={'password'} className={'signup-user'} onChange={passwordConfirmationChange}/></div>
          {!passwordMatch && <div style={{color: 'red'}}>Les deux mots de passes ne correspondent pas</div>}
          <Button className={'signup-user'} onClick={createPassword}>{isReinit === "true" ?"Modifier le mot de passe" :"Finaliser l'inscription"}</Button>
        </div>}
        {passwordInitMode && !user && <div style={{color: "red"}}>Lien non valide</div>}
        {!passwordInitMode && <div><Input placeholder="E-mail" className={isUsernameInValid()? 'invalid-input':'signup-user'} onChange={userNameChange} onBlur={sortDuField}/></div>}
        {!passwordInitMode && showUserExistError && <div style={{color: "red", marginBottom: "10px"}}>Ce nom d'utilisateur existe déjà!</div>}
        {!passwordInitMode && <div><Input placeholder="Nom" className={(!initialState && !form.lastName)? 'invalid-input':'signup-user'} onChange={lastNameChange}/></div>}
        {!passwordInitMode && <div><Input placeholder="Prénom" className={(!initialState && !form.firstName)? 'invalid-input':'signup-user'} onChange={firstNameChange}/></div>}
        {!passwordInitMode && <div><Input placeholder="Fournisseur" className={(!initialState && !form.providerText)? 'invalid-input':'signup-user'} onChange={providerChange}/></div>}
        {!passwordInitMode && <div><Button icon={<DownloadOutlined />} className={'signup-user'} onClick={ribUpload}>Upload RIB</Button></div>}
        {!passwordInitMode && <div className={'rib-div'}><a id={'rib-name'} onClick={downloadRib}></a></div>}
        {!passwordInitMode && <Button className={'signup-user'} onClick={signup} disabled={!ribUploaded}>S'inscrire</Button>}
        <div className={'sign-up'}>Déjà client ?<a onClick={login}> S'identifier</a></div>
        <input type="file" onChange={handleChangeSelectedFileRib} id={'input-file-rib'} style={{display: "none"}}/>
      </div>

      <Dialog
        open={showConfirmationDialog}
        onClose={toggleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Votre demande a été envoyée avec succès vous receverez un E-mail de confirmation quand elle sera traitée
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmationDialog2}
        onClose={toggleDialog2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              `Votre  ${
                isReinit === "true" ? "modification de mot de passe":"inscription"
              }a été finalisée avec succès`
            }
             
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={redirectLogin2}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={showConfirmationDialog3}
          onClose={toggleDialog3}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Votre inscription a été finalisée avec succès, vous allez être redirigé à la page de log in
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={redirectLogin}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
