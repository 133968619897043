import * as React from "react";
import {useEffect, useState} from "react";
import App from "./App";
import logo from "./z.png";
import {Button, Input} from "antd";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import InvoiceApi from "./InvoiceApi";

export default function Login() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState(false);
  let navigate = useNavigate()
  let user: any = {};
  const userLocal = window.localStorage.getItem("user")
  if (userLocal) {
    user = JSON.parse(userLocal)
  }
  let [username, setUsername] = useState(undefined)
  let [password, setPassword] = useState(undefined)
  let dispatch = useDispatch()

  useEffect(() => {
    if (user && user.username) {
      setIsAuthenticated(true)
      navigate('/dashboard')
    } else {
      setIsAuthenticated(false)
    }
  }, [])

  function loginBk () {
    // login('bk')
  }

  function loginProvider () {
    // login('provider')
  }

  function changeUserName(event: any) {
    setUsername(event.target.value)
  }

  function changePassword(e: any) {
    setPassword(e.target.value)
  }

  function login() {
    InvoiceApi.login(username, password, loginCallBack, loginFailed)
  }

  function forgotPassword(){
    navigate('/reinit-password')
  }

  function loginFailed() {
    setLoginError(true)
  }

  function loginCallBack(res: any, token: any) {
    dispatch({type: "SET_USER", user: res})
    navigate('/dashboard')
    setIsAuthenticated(true)
  }

  function signup() {
    navigate('/signup')
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    login()
  }

  return (
    <div className={'login-page'}>
      {isAuthenticated && <App></App>}
      {!isAuthenticated &&
      <div className={'login-box'}>

        <img src={logo} className={'login-logo'}/>
        <div className={'login-title'}>
            <h2 style={{color: '#fff'}}>INVOICE MANAGER</h2>
        </div>
        <form onSubmit={onFormSubmit}>
          <Input placeholder="E-mail" className={'login-user'} onChange={changeUserName}/>
          <Input placeholder="Password" className={'login-user'} onChange={changePassword} type={'password'}/>
          <Button className={'login-user'} onClick={login} htmlType="submit">Connexion</Button>
        </form>
{/*        <Button className={'login-user'} onClick={loginProvider}>Connexion Fournisseur</Button>
        <Button className={'login-user'} onClick={loginBk}>Connexion BK</Button>*/}
        <div className={'sign-up'}>Nouveau sur invoice manager ?<a onClick={signup}> S'inscrire</a></div>
        <div className={'sign-up'}>Mot de passe oublié ?<a onClick={forgotPassword}> Reinitialiser le mot de passe</a></div>
        
        {loginError && <div style={{color: 'red'}}>User ou Mot de passe erroné</div> }
      </div>}
    </div>
  )
}
