import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import EnhancedTableHeadTable from "./TableHead";
import TableBody from "@mui/material/TableBody";
import {DialogContent, TablePagination} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import InvoiceApi from "./InvoiceApi";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {EditOutlined, PlusOutlined} from "@ant-design/icons/lib";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'N°',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: "Nom d'utilisateur",
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: "Prénom",
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: true,
    label: "Nom",
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: "Rôle",
  },
  {
    id: 'modify',
    numeric: false,
    disablePadding: true,
    label: "Modifier",
  },
]

type Order = 'asc' | 'desc';

interface Data {
  id: string;
  status: string;
  type: string;
  billNumber: string;
  creationDate: string;
  name: string;
  price: string;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function UserManagement () {

  const [page, setPage] = React.useState(0);
  const [addUser, setAddUser] = React.useState(false);
  const [initialState, setInitialState] = React.useState(true);
  const [emailValid, setEmailValid] = React.useState(true);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  const [showConfirmationDialogUserExist, setShowConfirmationDialogUserExist] = React.useState(false);
  const [form, setForm] = React.useState({userStatus: 'VALID'});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>(undefined);
  let [filteredRows, setFilteredRows] = React.useState([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    InvoiceApi.getUsersByTypes('intern', usersReady)
  },[])

  function usersReady(res) {
    setFilteredRows(res)
  }
  
  function showForm() {
      setAddUser(!addUser)
  }

  function changeNom(e) {
      setForm({...form, lastName: e.target.value})
  }

  function changePrenom(e) {
    setForm({...form, firstName: e.target.value})
  }

  function changeRole(e) {
    setForm({...form, userType: e.target.value})
  }

  function changeUsername(e) {
    setForm({...form, username: e.target.value})
  }

  function changePassword(e) {
    setForm({...form, password: e.target.value})
  }

  function save() {
    setInitialState(false)
    const validEmail = testMail(form.username)
    setEmailValid(validEmail)
    if (form.firstName && form.userType && form.lastName && form.username && validEmail) {
      InvoiceApi.getUserByUsername(form.username, getByUsernameCb, usernameNotFound)
    }
  }

  function testMail(mail) {
    return mail && mail.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  function usernameNotFound() {
    InvoiceApi.createUser(form, saveCb)
  }

  function getByUsernameCb() {
    toggleDialogUserExist();
  }

  function saveCb(res) {
    toggleDialog();
  }

  function toggleDialog() {
    setShowConfirmationDialog(!showConfirmationDialog)
  }

  function toggleDialogUserExist() {
    setShowConfirmationDialogUserExist(!showConfirmationDialogUserExist)
  }

  function closeDialog() {
    toggleDialog()
    InvoiceApi.getUsersByTypes('intern', usersReady);
  }

  function formatUserType(type) {
    if(type=== 'MG') return 'Moyens généraux'
    if(type=== 'provider') return 'Fournisseur'
    if(type=== 'SF') return 'Services financiers'
    return type
  }

  return (<div>
    <div className={'user-top-div'}>
      <div className={'user-title'}><h2>Gestion des utilisateurs internes</h2></div>
      <div className={'user-plus'}><PlusOutlined onClick={showForm}></PlusOutlined></div>
    </div>
    {addUser && <div className={'user-form'}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '50ch' },
            }}
            noValidate
            autoComplete="off">
            <TextField error={!initialState && !form.lastName} label={'Nom'} onChange={changeNom}></TextField>
            <TextField error={!initialState && !form.firstName} label={'Prénom'} onChange={changePrenom}></TextField>
            <TextField error={!initialState && (!form.username || !emailValid)} label={"E-mail"} onChange={changeUsername}></TextField>
            {/*<TextField error={!initialState && !form.password} label={'Mot de passe'} onChange={changePassword} type={'password'}></TextField>*/}
            <FormControl sx={{ m: 1, minWidth: '25ch' }}>
              <InputLabel id="demo-simple-select-helper-label">Rôle *</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Ordonnateur"
                error={!initialState && !form.userType}
                onChange={changeRole}
              >
                <MenuItem value={'DSI'}>DSI</MenuItem>
                <MenuItem value={'MG'}>Moyens généraux</MenuItem>
                <MenuItem value={'SF'}>Services financiers</MenuItem>
              </Select></FormControl>
        </Box>
        <div>
          <Button variant={'contained'} className={'user-form-button'} size={'large'} onClick={save}>Ajouter</Button>
        </div>
    </div>}
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" className={'table-management'}>
        <EnhancedTableHeadTable
          // numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          headCells={headCells}
          // onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={filteredRows.length}
        />
        <TableBody>
          {stableSort(filteredRows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            // <RowMainTable key={row.id} row={row} />
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell></TableCell>
              <TableCell >{row.id}</TableCell>
              <TableCell>{row.username}</TableCell>
              <TableCell>{row.firstName}</TableCell>
              <TableCell>{row.lastName}</TableCell>
              <TableCell>{formatUserType(row.userType)}</TableCell>
              <TableCell><EditOutlined></EditOutlined></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      labelRowsPerPage={'Nombre de lignes par page'}
      component="div"
      count={filteredRows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    <Dialog
      open={showConfirmationDialog}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirmation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Utilisateur crée
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>OK</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={showConfirmationDialogUserExist}
      onClose={toggleDialogUserExist}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Erreur"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ce nom d'utilisateur existe déjà!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleDialogUserExist}>OK</Button>
      </DialogActions>
    </Dialog>
  </div>)
}
