import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import * as React from "react";
import {Select} from "antd";
import Button from "@mui/material/Button";
import InvoiceApi from "./InvoiceApi";
import {useEffect, useState} from "react";
import {URL_INVOICE_API} from "./Constantes";



export default function RowTableRequests(props: any) {
  let {user} = props

  function getProviderText() {
    return user.providerText? user.providerText: '-'
  }

  function getProviderAdmin() {
    return user.provider? user.provider.companyName: <Select style={{width: '80%'}} placeholder="Fournisseur" onChange={providerChange}>{props.optionsSelect}</Select>
  }

  function showStatus() {
    if(user.userStatus == 'VALID') return 'VALIDE'
    else return <Button variant={'contained'} onClick={valider}>Valider la demande</Button>
  }

  function providerChange(val: any) {
      user.provider = {id: val}
  }

  function valider() {
      if (user.provider) {
          InvoiceApi.modifyUser({...user, userStatus: 'VALID'}, validerCb)
      } else {
          props.toggleDialogErreur()
      }
  }

  function validerCb() {
    props.updateComponent()
  }

  function ribFileClick(file: any) {
      return function () {
          InvoiceApi.downloadFile(URL_INVOICE_API + '/file/download/' + file.fileId, file.fileName)
      }
  }

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell></TableCell>
      <TableCell >{user.id}</TableCell>
      <TableCell>{user.username}</TableCell>
      <TableCell>{user.firstName}</TableCell>
      <TableCell>{user.lastName}</TableCell>
      <TableCell>{getProviderText()}</TableCell>
      <TableCell>{getProviderAdmin()}</TableCell>
      <TableCell>{showStatus()}</TableCell>
        <TableCell><a onClick={ribFileClick(user.ribFile)}>{user.ribFile?.fileName}</a></TableCell>
    </TableRow>
  )
}