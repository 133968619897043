import {TextField} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {formatUserType} from "./Constantes";

export default function MyAccount() {

  const userStorage = window.localStorage.getItem("user")
  const user = userStorage? JSON.parse(userStorage): undefined

  return (
    <div className={'account-text'}>
      <h2>Mon compte</h2>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1 },
        }}
        noValidate
        autoComplete="off">
          <div>
            <TextField label={'Nom'} value={user.lastName} className={'text-account'}></TextField>
            <TextField label={'Prénom'} value={user.firstName} className={'text-account'}></TextField>
          </div>
          <div>
            <TextField label={"Nom d'utilisateur"} value={user.username} className={'text-account'}></TextField>
            <TextField label={'Rôle'} value={formatUserType(user.userType)} className={'text-account'}></TextField>
          </div>
      </Box>

      {user.provider &&
        <div>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off">
              <div>
                <TextField label={'Nom de la société'} value={user.provider.companyName} className={'text-account'}></TextField>
                <TextField label={'Description'} value={user.provider.companyDetails} className={'text-account'}></TextField>
              </div>
              <div>
                <TextField label={"Addresse"} value={user.provider.address} className={'text-account'}></TextField>
                <TextField label={'Téléphone'} value={user.provider.phone} className={'text-account'}></TextField>
              </div>
              <div>
                <TextField label={'RIB'} value={user.provider.rib} className={'text-account'}></TextField>
              </div>
          </Box>
        </div>
      }
    </div>
  )
}
