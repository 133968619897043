import moment from "moment";
// @ts-nocheck
export const CREATE = 'Draft'
export const VERIF = 'En cours de vérification'
export const DEVIS = 'En attente  de devis'
export const SIGN = 'En cours de signature'
export const PAY = 'En cours de paiement'
export const PAID = 'Payée'
export const IN_PROGRESS_PROVIDER = 'En cours de traitement'

export const PROVIDER_ROLE = 'provider'

//s export const URL_INVOICE_API = "http://localhost:8080"
 export const URL_INVOICE_API = "https://www.bkinvoicedigitaloffice.ma/invoices-api"

//  export const URL_INVOICE_API = "http://10.110.101.92:8080/invoices-api"

//  export const URL_INVOICE_API = "http://10.110.101.92:8080/invoices-api-test"

export type Order = 'asc' | 'desc';

export interface Data {
    id: string;
    status: string;
    type: string;
    billNumber: string;
    creationDate: string;
    name: string;
    price: string;
}

export function inverserDateTime (dateAndTime: any) {
    const dateSplit = dateAndTime.split('T')
    const time = dateSplit[1].slice(0,5)
    const date = inverserDate(dateSplit[0])
    return date + ' ' + time
}

export function inverserDate (date: any) {
    const dateSplit = date.split('-')
    if (dateSplit.length == 3) {
        return dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0]
    }
    return date
}

export function formatUserType(type: any) {
    if (type == 'MG') return 'Moyens généraux'
    if (type == PROVIDER_ROLE) return 'Fournisseur'
    if (type == 'SF') return 'Services financiers'
    return type
}

//todo remove weekends
export function calculerDelaiTraitement(row: any) {
    var momentBusiness = require('moment-business-days');

    if (row.status == PAID) {
        if (row?.historyList?.length) {
            const statusPaiement = row.historyList.filter((history: any) => {
                return history.nouveauStatut == PAID
            })
            const statusVerif = row.historyList.filter((history: any) => {
                return history.nouveauStatut == VERIF
            })
            const datePaiement = statusPaiement.length? statusPaiement[0].dateAction: undefined
            const date2History = statusVerif.length? statusVerif[0].dateAction: undefined
            if (datePaiement && date2History) {
                return momentBusiness(date2History, 'YYYY-MM-DD').businessDiff(momentBusiness(datePaiement, 'YYYY-MM-DD'))
            } else {
                return undefined;
            }

        }
        return undefined
    } else {
        const statusVerif = row.historyList.filter((history: any) => {
            return history.nouveauStatut == VERIF
        })
        const date2History = statusVerif.length? statusVerif[0].dateAction: undefined
        return momentBusiness(date2History, 'YYYY-MM-DD').businessDiff(moment())
    }
}

export function formatNumbercell (num: any) {
    return num
      ? num
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
      : num;
};

export function removeDoubleZero (numberCell: any) {
    if (numberCell && numberCell.length>2) {
        if (numberCell.substring(numberCell.length-3,numberCell.length) == '.00') {
            return numberCell.substring(0, numberCell.length-3)
        }
    }
    return numberCell;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: any,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'N°',
    },
    {
        id: 'username',
        numeric: false,
        disablePadding: true,
        label: "Nom d'utilisateur",
    },
    {
        id: 'firstName',
        numeric: false,
        disablePadding: true,
        label: "Prénom",
    },
    {
        id: 'lastName',
        numeric: false,
        disablePadding: true,
        label: "Nom",
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: true,
        label: "Raison sociale demandée",
    },
    {
        id: 'company',
        numeric: false,
        disablePadding: true,
        label: "Raison sociale attribuée par l'administrateur",
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: "Statut",
    },
    {
        id: 'rib',
        numeric: false,
        disablePadding: true,
        label: "RIB",
    },

]

export const headCellsProviders = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'N°',
    },
    {
        id: 'companyname',
        numeric: false,
        disablePadding: true,
        label: "Raison sociale",
    },
    {
        id: 'company_details',
        numeric: false,
        disablePadding: true,
        label: "Contact facturation",
    },
    {
        id: 'address',
        numeric: false,
        disablePadding: true,
        label: "Adresse",
    },
/*    {
        id: 'rib',
        numeric: false,
        disablePadding: true,
        label: "RIB",
    },*/
    {
        id: 'phone',
        numeric: false,
        disablePadding: true,
        label: "Téléphone",
    },

]

interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
}

export const headCellsMainTableNotProvider: readonly HeadCell[] = [
    /*  {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'N°',
      },*/
    {
        id: 'invoiceRef',
        numeric: false,
        disablePadding: false,
        label: 'Référence',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Statut',
    },
    {
        id: 'numBC',
        numeric: false,
        disablePadding: false,
        label: 'Bon de commande / Contrat',
    },
    {
        id: 'creationdate',
        numeric: false,
        disablePadding: false,
        label: 'Date de création',
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'Prestation',
    },
    {
        id: 'ordonnateur',
        numeric: false,
        disablePadding: false,
        label: 'Ordonnateur',
    },
    {
        id: 'price',
        numeric: false,
        disablePadding: false,
        label: 'Montant HT',
    },

    {
        id: 'delai',
        numeric: false,
        disablePadding: false,
        label: 'Délai de traitement',
    },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: 'Etat',
    },
    {
        id: 'provider',
        numeric: false,
        disablePadding: false,
        label: 'Fournisseur',
    },
    /*  {
        id: 'lastUser',
        numeric: false,
        disablePadding: false,
        label: 'Dernier validateur',
      },*/

];

export const headCellsMainTableProvider: readonly HeadCell[] = [
    /*  {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'N°',
      },*/
    {
        id: 'invoiceRef',
        numeric: false,
        disablePadding: false,
        label: 'Référence',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Statut',
    },
    {
        id: 'numBC',
        numeric: false,
        disablePadding: false,
        label: 'Bon de commande / Contrat',
    },
    {
        id: 'creationdate',
        numeric: false,
        disablePadding: false,
        label: 'Date de création',
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'Prestation',
    },
    {
        id: 'ordonnateur',
        numeric: false,
        disablePadding: false,
        label: 'Ordonnateur',
    },
    {
        id: 'price',
        numeric: false,
        disablePadding: false,
        label: 'Montant HT',
    },
    /*  {
        id: 'lastUser',
        numeric: false,
        disablePadding: false,
        label: 'Dernier validateur',
      },*/

];
