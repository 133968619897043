import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import {CREATE, DEVIS, IN_PROGRESS_PROVIDER, PAID, PAY, PROVIDER_ROLE, SIGN, VERIF} from "./Constantes";


export default function HorizontalLinearStepper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const stepRedux = useSelector((state => state.invoice.invoice.status))
    const invoice = useSelector((state => state.invoice.invoice))
    const constantes = useSelector((state => state.invoice.constantes))
   
    
   // const secondStep= superieurCentMille? DEVIS: VERIF;
    const [secondStep, setSecondStep] = React.useState(VERIF);

    const user = JSON.parse(window.localStorage.getItem("user"));

    const steps = user.userType === PROVIDER_ROLE? [CREATE,IN_PROGRESS_PROVIDER, PAID]: [CREATE, secondStep, PAY, PAID];

    const isStepOptional = (step: number) => {
        return step === 10;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    useEffect(()=>{   
        setSecondStep( user.userType === PROVIDER_ROLE ? VERIF: stepRedux);


    }, [ user,stepRedux ]);

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        if (user.userType == PROVIDER_ROLE) {
            if (stepRedux == CREATE) setActiveStep(0)
            if (stepRedux == VERIF) setActiveStep(1)
            // if (stepRedux == SIGN) setActiveStep(1)
            if (stepRedux == PAY) setActiveStep(1)
            if (stepRedux == PAID) setActiveStep(2)
        } else {
            if (stepRedux == CREATE) setActiveStep(0)
            if (stepRedux == VERIF) setActiveStep(1)
            // if (stepRedux == SIGN) setActiveStep(2)
            if (stepRedux == PAY) setActiveStep(2)
            if (stepRedux == PAID) setActiveStep(3)
        }
    }, [stepRedux])

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                </React.Fragment>
            )}
        </Box>
    );
}
