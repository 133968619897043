import * as React from 'react';
import {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import {Box, LinearProgress, TablePagination, TextField} from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import Button from '@mui/material/Button';
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import InvoiceApi from "./InvoiceApi";
import RowMainTable from "./RowMainTable";
import EnhancedTableHeadTable from "./TableHead";
import {
  calculerDelaiTraitement,
  getComparator,
  headCellsMainTableNotProvider,
  headCellsMainTableProvider, PAID,
  PROVIDER_ROLE,
  stableSort, URL_INVOICE_API
} from "./Constantes";
import axios from "axios";

const localeMap: any = {
    en: enLocale,
    fr: frLocale,
    ru: ruLocale,
    de: deLocale,
};

type Order = 'asc' | 'desc';

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface Data {
  status: string;
  type: string;
  billNumber: string;
  creationDate: string;
  name: string;
  price: string;
}

export default function CollapsibleTable(props: any) {

  const [locale, setLocale] = React.useState('fr')
  let [searchInput, setSearchInput] = useState(null);
  let [filteredRows, setFilteredRows] = React.useState([]);
  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);
  const [invoices, setInvoices] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState(undefined);
  const userLocal = window.localStorage.getItem("user")
  const userType = userLocal? JSON.parse(userLocal).userType: undefined
  const userId = userLocal? JSON.parse(userLocal).id : undefined
  const mode = props?.mode
  const invoicesRedux = useSelector((state: any) => state.invoice.invoices)
  const constantes = useSelector((state: any) => state.invoice.constantes)

  useEffect(() => {

    const fetchConstantes: any = async () => {
      const constants: any = await axios({
        method: "GET",
        url: URL_INVOICE_API + "/constantes",
        responseType: "stream",
        headers: {Authorization: `Bearer ${InvoiceApi.getToken()}`}
      });
      return constants;
    }

    if (mode == 'retard') {
      setIsLoading(false)
      const retardInvoice = invoicesRedux.filter((invoice: any) => {
        const delai = calculerDelaiTraitement(invoice);
        const delai_max_const = constantes.filter((constante:any) => {return constante.name=='DELAY'})
        return delai > delai_max_const[0].value && invoice.status != PAID;
      })
      setInvoices(retardInvoice)
      setFilteredRows(retardInvoice)
    } else if (mode == 'closed') {
      setIsLoading(false)
      const closedInvoice = invoicesRedux.filter((invoice: any) => {
        return invoice.status == PAID
      })
      setInvoices(closedInvoice)
      setFilteredRows(closedInvoice)
    } else if (mode == 'cours') {
      setIsLoading(false)
      const closedInvoice = invoicesRedux.filter((invoice: any) => {
        return invoice.status != PAID
      })
      setInvoices(closedInvoice)
      setFilteredRows(closedInvoice)
    } else {
      fetchConstantes().then(res =>
      {
        dispatch({type: 'SET_CONSTANTES', constantes: res.data})

        if (userType == 'admin' || userType == 'bk_read' || userType == 'SF') {
          InvoiceApi.getAllInvoices(invoiceCallBackConst(res.data), cbTokenExpired)
        } else if (userType == 'DSI') {
          InvoiceApi.getInvoicesDsi(invoiceCallBackConst(res.data), cbTokenExpired)
        } else if (userType == 'MG') {
          InvoiceApi.getInvoicesMg(invoiceCallBackConst(res.data), cbTokenExpired)
        } else if (userId) {
          InvoiceApi.getInvoiceByUserId(userId, invoiceCallBackConst(res.data), cbTokenExpired)
        } else {
          console.log('login error');
        }
      }).catch((error: Error) => {
        if (error.message.includes('401')) {
          window.localStorage.removeItem("user");
          window.location.reload()
        }
      });

    }
  },[])

  function cbTokenExpired() {
    window.localStorage.removeItem("user");
    navigate('/')
    window.location.reload()
  }

  function invoiceCallBackConst(dbConstants: any) {
    return function invoiceCallback(res: any) {
      let invoicesBis: any = []
      res.forEach((invoice: any) => {
        invoicesBis.push ({...invoice, delai: calculerDelaiTraitement(invoice)})
      })
      setInvoices(invoicesBis)
      setFilteredRows(invoicesBis)
      const retardInvoice = invoicesBis.filter((invoice: any) => {
        return invoice.delai > dbConstants[0].value && invoice.status != PAID;
      })
      dispatch({type: 'SET_INVOICES', invoices: invoicesBis})
      dispatch({type: 'SET_DELAY_HORS', horsDelay: retardInvoice?.length})
      setIsLoading(false)

    }
  }

  function applyAllFilter(searchText: any, fromDate: any, toDate: any) {
    let list = invoices
    if (searchText) {
      list = applySearchInputFilter(searchText, list);
    }
    if (toDate) {
      list = applyToFilter(toDate, list);
    }
    if (fromDate) {
      list = applyFromFilter(fromDate, list);
    }
    return list;
  }

  function applyToFilter(date: any, list: any) {
    return list.filter((row: any) => {
      return moment(row.creationdate, "YYYY-MM-DD").toDate() < date
    })
  }

  function applyFromFilter(date: any, list: any) {
    return list.filter((row: any) => {
      return moment(row.creationdate, "YYYY-MM-DD").toDate() > date
    })
  }

  function applySearchInputFilter(inputText: any, list: any) {
    const searchRegex = new RegExp(escapeRegExp(inputText), 'i');
    return list.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        if (!row[field]) {
          return false;
        }
        if (field && field == 'user') {
          return searchRegex.test(row.user?.provider?.companyName?.toString());
        }
        return searchRegex.test(row[field].toString());
      });
    });
  }

  function changeToValue(newValue: any)  {
    setToValue(newValue);
    setFilteredRows(applyAllFilter(searchInput, fromValue, newValue));
  }

  function changeFromValue(newValue: any)  {
    setFromValue(newValue);
    setFilteredRows(applyAllFilter(searchInput, newValue, toValue));
  }

  function changeInputSearch(event: any) {
    const searchValue = event.target.value;
    setSearchInput(searchValue);
    setFilteredRows(applyAllFilter(searchValue, fromValue, toValue));
  }

  function navigateToNewInvoice() {
      dispatch({ type: "INVOICE_DETAIL", invoice: {} });
      navigate('/invoice-crud/new')
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property:any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div>
      {mode == 'retard' && <h1 style={{fontSize: '26px'}}>Factures en retard</h1>}
      {mode == 'cours' && <h1 style={{fontSize: '26px'}}>Factures en cours</h1>}
      {mode == 'closed' && <h1 style={{fontSize: '26px'}}>Factures payées</h1>}
      {!mode && <h1 style={{fontSize: '26px'}}>Mes factures</h1>}
      <div className={'champs-search'}>
        <TextField id="outlined-basic" label="Recherche" variant="outlined" size={"medium"} className={'search-input'} value={searchInput} onChange={changeInputSearch}> </TextField>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
          <div className={'du-dash'}>
            <DatePicker
                  label="Du"
                  value={fromValue}
                  onChange={changeFromValue}
                  renderInput={(params) => <TextField {...params} />}
              />
          </div>
          <div className={'du-dash'}>
            <DatePicker
                label="Au"
                value={toValue}
                onChange={changeToValue}
                renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>
        {userType=='provider'  &&  <div className={'new-button-div'}><Button variant="contained" className={'new-button'} onClick={navigateToNewInvoice}>Nouvelle facture</Button></div>}
      </div>
      <TableContainer component={Paper} className={'main-table'}>
        <Table aria-label="collapsible table">
          <EnhancedTableHeadTable
            // numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            headCells={userType==PROVIDER_ROLE? headCellsMainTableProvider: headCellsMainTableNotProvider}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredRows.length}
          />
          <TableBody>
            {stableSort(filteredRows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <RowMainTable key={row.id} row={row} />
            ))}
            {!filteredRows?.length && <tr><td></td><td><div>Aucune entrée</div></td></tr>}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelRowsPerPage={'Nombre de lignes par page'}
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
