import * as React from "react";

export default function BlocStats (props: any) {
    const tauxReussite = props?.tauxReussite
    const tauxMois = props?.tauxMois
    const tauxReussiteNMoinUn = props?.tauxReussiteNMoinUn
    const title = props?.title
    return (
      <div className={'titles-indic'}><div style={{fontWeight: "bold"}}>{title} </div>
        <span>YTD: </span>
        <span style={{fontWeight: "bold"}}>{tauxReussite.toFixed(0)}%</span>
        <div>
          <span className={'squeeze'}>MTD: </span>
          <span style={{fontWeight: "bold"}}>{tauxMois.toFixed(0)}%</span>
        </div>
        <div>
          <span className={'squeeze'}>Y-1: </span>
          <span style={{fontWeight: "bold"}}>{tauxReussiteNMoinUn.toFixed(0)}%</span>
        </div>
      </div>
    )
}