import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from "react-redux";
import {CREATE, DEVIS, PAID, PAY, SIGN, VERIF} from "./Constantes";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs() {
  const openConfirmation = useSelector((state: any) => state.invoice.openConfirmation)
  const currentStatus = useSelector((state: any) => state.invoice.invoice.status)
  let dispatch = useDispatch()

  const toggleDialog = () => {
    dispatch({ type: "INVOICE_DETAIL_TOGGLE_CONFIRMATION" })
  };

  return (
    <div>
      <BootstrapDialog
        onClose={toggleDialog}
        aria-labelledby="customized-dialog-title"
        open={openConfirmation}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggleDialog}>
          Confirmation
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {currentStatus== CREATE && <Typography gutterBottom>
            La facture a été retournée au fournisseur pour revérification après upload d'un devis contradictoire
          </Typography>}
          {(currentStatus== VERIF || currentStatus== DEVIS ) && <Typography gutterBottom>
            La facture a été créée avec succès et transmise au service financier pour traitement.
          </Typography>}
          {currentStatus==SIGN && <Typography gutterBottom>
            La facture a été validée avec succès
          </Typography>}
          {currentStatus==PAY && <Typography gutterBottom>
            La facture a été validée avec succès
          </Typography>}
          {currentStatus==PAID && <Typography gutterBottom>
            La facture a été payée avec succès
          </Typography>}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={toggleDialog}>
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
